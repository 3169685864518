// Filename - components/Footer.js

import React from "react";
import logo from "../../assets/images/WhiteLogo.png";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
import { Input } from "antd";

const Footer = () => {
    return (
        <Box>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img
                    src={logo} height={40}
                />
            </div>
            <FooterContainer >
                <Row >
                    <Column>
                        <Heading>Main Menu</Heading>
                        <FooterLink href="#">
                            Home
                        </FooterLink>
                        <FooterLink href="#">
                            Summer Collection
                        </FooterLink>
                        <FooterLink href="#">
                            Winter Collection
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Contact Us</Heading>
                        <FooterLink >
                            +20 111 111 1111
                        </FooterLink>
                        <FooterLink >
                            info@detach.com
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Social Media</Heading>
                        {/* <FooterLink href="#">
							<i className="fab fa-facebook-f">
								<span
									style={{
										marginLeft: "10px",
									}}
								>
									Facebook
								</span>
							</i>
						</FooterLink> */}
                        <FooterLink href="#">
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/detach.eg/" style={{ display: "flex", gap: "10px", textDecoration: "none", justifyContent: "center", alignItems: "center" }}>

                                <svg style={{ fill: "white", width: "20px", height: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="instagram"><path d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"></path><path d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"></path><circle cx="12.3" cy="3.7" r=".533"></circle></svg>
                                <span style={{ color: "white" }}>
                                    Instagram
                                </span>
                            </a>
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Subscribe</Heading>
                        <div className="subscribe-footer">
                            <p style={{ fontFamily: "'Roboto', sans-serif", color: "white", marginTop: "0" }}>Stay up-to-date with our latest news and updates!</p>
                            <Input placeholder="Enter your email address" />
                        </div>
                    </Column>
                </Row>
            </FooterContainer>
        </Box>
    );
};
export default Footer;
