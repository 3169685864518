import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, Select, Upload, message, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

export function HomeAdmin() {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);

    const [collections, setCollections] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [homeData, setHomeData] = useState({
        text: "",
        buttonNavigation: ""
    });

    const [backgroundImage, setBackgroundImage] = useState(null);

    useEffect(() => {
        const getHomeData = async () => {
            setLoading(true);
            const response = await axios.get(`https://api.detacheg.com/api/home`);
            form.setFieldsValue({
                text: response.data[0].text,
                buttonNavigation: response.data[0].buttonNavigation
            });
            setHomeData(
                {
                    text: response.data[0].text,
                    buttonNavigation: response.data[0].buttonNavigation
                }
            );
            setBackgroundImage(response.data[0].backgroundImage);
            setLoading(false);
        }
        getHomeData();
    }, [isUpdated]);

    useEffect(() => {
        const getCollections = async () => {
            setLoading(true);
            const response = await axios.get(`https://api.detacheg.com/api/collections`);
            const collections = response.data.map(collection => {
                return {
                    label: collection.name,
                    value: collection.name
                }
            });
            setCollections(collections);
            setLoading(false);
        }
        getCollections();
    }, []);

    const props = {
        name: "file",
        multiple: false,
        action: "https://api.detacheg.com/api/images/single",
        async onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                await axios.put(`https://api.detacheg.com/api/home`, {
                    backgroundImage: info.file.response.url
                });
                setIsUpdated(!isUpdated);
            }
            if (status === "done") {
                info.fileList.splice(0, info.fileList.length - 1);
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const updateHomeData = async () => {
        await axios.put(`https://api.detacheg.com/api/home`, homeData);
        setIsUpdated(!isUpdated);
        setIsEditing(!isEditing);
    }

    return (
        <div style={{ width: "1200px" }}>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <Spin size="large" />
                </div>
            ) : (
                <Form
                    name="basic"
                    form={form}
                >
                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        <Form.Item
                            label="Background Image"
                            name="backgroundImage"
                        >
                            <Image width={320} height={400} src={`https://api.detacheg.com/api/images/getImage?name=${backgroundImage}`} alt="Background Image" />
                        </Form.Item>

                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </div>
                    <Form.Item
                        label="Text"
                        name="text"
                    >
                        <Input
                            defaultValue={homeData?.text ? homeData?.text : ''}
                            onChange={(e) =>
                                setHomeData({ ...homeData, text: e.target.value })
                            }
                            readOnly={!isEditing} />
                    </Form.Item>
                    <Form.Item
                        label="Button Navigation"
                        name="buttonNavigation"
                    >
                        <Select
                            placeholder="Select a collection"
                            options={collections}
                            defaultValue={homeData?.buttonNavigation ? homeData.buttonNavigation : ''}
                            onChange={(value) => setHomeData({ ...homeData, buttonNavigation: value })}
                            disabled={!isEditing}
                        />
                    </Form.Item>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                        <Form.Item>
                            <Button type="primary" onClick={() => setIsEditing(!isEditing)} disabled={isEditing}>
                                Edit
                            </Button>
                        </Form.Item>
                        {
                            isEditing &&
                            <Form.Item>
                                <Button type="primary" onClick={
                                    () => {
                                        setIsEditing(!isEditing);
                                        setIsUpdated(!isUpdated)
                                    }
                                }
                                >
                                    Cancel
                                </Button>
                            </Form.Item>
                        }
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={!isEditing} onClick={updateHomeData}>
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            )}
        </div>
    );
}

export default HomeAdmin;