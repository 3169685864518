import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import ProductCard from "../productCard/ProductCard";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./collection.css";
import { Divider, Breadcrumb } from "antd";
import Footer from "../Footer/Footer";

export default function Collection() {
  const [products, setProducts] = useState([]);
  const { collectionId } = useParams(); // Get collection ID from URL
  const navigate = useNavigate();




  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await axios.get("https://api.detacheg.com/api/collections/published");
        const formattedCollections = response.data.map((collection) => collection.name);
        if (collectionId && !formattedCollections.includes(collectionId)) {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchCollections();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await axios.get(`https://api.detacheg.com/api/products`);
        const filteredProducts = res.data.filter(
          (product) => product.collection === collectionId
        );
        setProducts(filteredProducts);
      } catch (err) {
        console.error(err);
      }
    };

    if (collectionId) { // Fetch products only if collectionId exists
      getProducts();
    }
  }, [collectionId]);

  return (
    <>
      <Divider />
      <Breadcrumb style={{ marginLeft: "20px" }} items={[
        { title: <a href="/">Home</a> },
        { title: collectionId || "Collection" } // Display collection name or "Collection" if not available
      ]} />
      <h1 style={{ textAlign: "center", fontFamily: "'Roboto', sans-serif" }}>
        {collectionId || "Collection"}
      </h1>
      {products.length > 0 && ( // Display products only if there are products in the collection
        <div style={{ marginBottom: "50px", marginTop: "50px", paddingLeft: "20px", paddingRight: "20px" }}>
          <Carousel showThumbs={false} swipeable={true} dynamicHeight={false} showStatus={false} showIndicators={false}>
            <div className="carousel-container" style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              {products.map((product) => (
                <ProductCard
                  key={product._id}
                  id={product._id}
                  image={`https://api.detacheg.com/api/images/getImage?name=${product?.items[0]?.images[0]}`}
                  brand={{ name: product.name }}
                  originalPrice={
                    product?.items[0]?.subItems[0]?.price
                      ? product?.items[0]?.subItems[0]?.price
                      : 0
                  }
                  price={
                    product?.items[0]?.subItems[0]?.priceAfterDiscount
                      ? product?.items[0]?.subItems[0]?.priceAfterDiscount
                      : product?.items[0]?.subItems[0]?.price
                  }
                />
              ))}
            </div>
          </Carousel>
        </div>
      )}
      <Footer />
    </>
  );
}
