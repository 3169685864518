import React, { useState, useContext } from "react";
// import "./checkout.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import colorNameList from 'color-name-list';
import axios from "axios";
import { AppContext } from "../../AppContext";
import style from "./checkout.module.css";

export default function CheckoutItem({ productID, subItemID, size, color, image, name, price, quantity }) {

    const { setCartItemsChanged, cartItemsChanged } = useContext(AppContext);

    const getColorName = (hex) => {
        const color = colorNameList.find(c => c.hex.toUpperCase() === hex.toUpperCase());
        return color ? color.name : "Unknown Color";
    };

    const incrementQuantity = async () => {
        try {
            await axios.put(`https://api.detacheg.com/api/cart/increment/${localStorage.getItem('token')}/${productID}/${subItemID}`); // You may need to pass the itemID as well
            setCartItemsChanged(!cartItemsChanged);
            // You may want to update the quantity in the UI after successful increment
        } catch (error) {
        }
    }

    const decrementQuantity = async () => {
        try {
            await axios.put(`https://api.detacheg.com/api/cart/decrement/${localStorage.getItem('token')}/${productID}/${subItemID}`); // You may need to pass the itemID as well
            setCartItemsChanged(!cartItemsChanged);
            // You may want to update the quantity in the UI after successful decrement
        } catch (error) {
        }
    }

    const onRemove = async () => {
        try {
            await axios.put(`https://api.detacheg.com/api/cart/remove/${localStorage.getItem('token')}/${productID}/${subItemID}`); // You may need to pass the itemID as well
            setCartItemsChanged(!cartItemsChanged);
            // You may want to update the quantity in the UI after successful decrement
        } catch (error) {
        }
    }

    return (
        <div className={style.cartItem} style={{ paddingTop: "20px" }}>
            <img src={image} alt={name} />
            <div className={style.itemDetails}>
                <h3>{name}</h3>
                <p>price: EGP {price}</p>
                <h3 className={style.size}>size: {size}</h3>
                <h3 className={style.color}>color: {getColorName(color)}</h3>
                <div className={style.quantityControls}>
                    <div className={style.quantityContainer}>
                        <button className={style.quantityButton} onClick={decrementQuantity}><FontAwesomeIcon icon={faMinus} /></button>
                        <span className={style.quantity}>{quantity}</span>
                        <button className={style.quantityButton} onClick={incrementQuantity}><FontAwesomeIcon icon={faPlus} /></button>
                    </div>
                    <div className={style.removeButtonContainer}>
                        <button className={style.removeButton} onClick={onRemove}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
