import React from "react";
import Card from "react-bootstrap/Card";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DatePicker, Form, Spin } from "antd";
import { useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

ChartJS.register(ArcElement, Tooltip, Legend);

function CurrentShipmentsCard() {

  const [loading, setLoading] = useState(false);

  const [totalStatus, setTotalStatus] = useState([
    { Status: "Delivered", Count: 1 },
    { Status: "Undelivered", Count: 2 },
    { Status: "Out For Delivery", Count: 3 },
    { Status: "New", Count: 4 },
  ]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleDateRangeChange = (dates) => {
    if (dates === null) {
      setFromDate(null);
      setToDate(null);
    } else {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    }
  };
  const totalCounts = totalStatus.reduce(
    (total, status) => total + status.Count,
    0
  );

  const deliveredCount = totalStatus.some(
    (status) => status.Status === "Delivered" && status.Count > 0
  )
    ? totalStatus
      .filter((status) => status.Status === "Delivered" && status.Count > 0)
      .map((status) => status.Count)
    : [0];
  const unDeliveredCount = totalStatus.some(
    (status) => status.Status === "Undelivered" && status.Count > 0
  )
    ? totalStatus
      .filter((status) => status.Status === "Undelivered" && status.Count > 0)
      .map((status) => status.Count)
    : [0];
  const withCourierCount = totalStatus.some(
    (status) => status.Status === "Out For Delivery" && status.Count > 0
  )
    ? totalStatus
      .filter(
        (status) => status.Status === "Out For Delivery" && status.Count > 0
      )
      .map((status) => status.Count)
    : [0];
  const newCount = totalStatus.some(
    (status) => status.Status === "New" && status.Count > 0
  )
    ? totalStatus
      .filter((status) => status.Status === "New" && status.Count > 0)
      .map((status) => status.Count)
    : [0];

  const percentage = [
    ((deliveredCount / totalCounts) * 100).toFixed(2),
    ((unDeliveredCount / totalCounts) * 100).toFixed(2),
    ((withCourierCount / totalCounts) * 100).toFixed(2),
    ((newCount / totalCounts) * 100).toFixed(2),
  ];
  const data = {
    labels: [
      `Delivered:${percentage[0] ? percentage[0] : 0}%`,
      `Undelivered:${percentage[1] ? percentage[1] : 0}%`,
      `Out For Delivery:${percentage[2] ? percentage[2] : 0}%`,
      `New :${percentage[3] ? percentage[3] : 0}%`,
    ],
    datasets: [
      {
        label: "# of Shipments",
        data: [deliveredCount ? deliveredCount : 0, unDeliveredCount ? unDeliveredCount : 0, withCourierCount ? withCourierCount : 0, newCount ? newCount : 0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };



  // useEffect(() => {
  //   setLoading(true);
  //   const getAllStatus = async () => {
  //     const response = await api.post(`status-count-by-subAccountID`,
  //       {
  //         fromDate: fromDate,
  //         toDate: toDate,
  //       }
  //     );
  //     setTotalStatus(response.data);
  //     setLoading(false);
  //   };
  //   getAllStatus();
  // }, [fromDate, toDate]);

  return (

    <Card style={{ width: "50%" }}>
      {/* <Card.Header>New Pickup</Card.Header> */}
      <Form.Item label="Filter" style={{ marginLeft: "20px", marginTop: "20px" }} >
        <RangePicker onChange={handleDateRangeChange} allowClear />
      </Form.Item>
      <Card.Body>
        <Spin tip="Loading" size="large" spinning={loading} indicator={<LoadingOutlined />}>
          <Pie data={data} />
        </Spin>
        <br />
      </Card.Body>
    </Card>

  );
}

export default CurrentShipmentsCard;
