import React from 'react';
import { Row, Col } from 'antd';
import Card from "react-bootstrap/Card";

export function AddProduct() {
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Card style={{ width: "100%" }}>
                        <Card.Header>New Pickup</Card.Header>
                        <Card.Body>
                            Test
                        </Card.Body>
                    </Card>
                </Col>
                <Col span={12} xl={6} lg={8} md={12} sm={24} xs={24}>
                    <Card style={{ width: "100%" }}>
                        <Card.Header>New Pickup</Card.Header>
                        <Card.Body>
                            Test
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}