import React, { Component } from "react";
import CurrentShipmentsCard from "./Content/CurrentShipmentsCard";
import ShipmentsOFD from "./Content/ShipmentsOFD";

class Dashboard extends Component {
  render() {
    return (
      <div style={{ display: "flex", gap: "20px", width: "100%" }}>
        <ShipmentsOFD />
        <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "16px" }}>
          <CurrentShipmentsCard />
        </div>
      </div>
    );
  }
}
export default Dashboard;
