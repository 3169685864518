import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
    const [cartItemsChanged, setCartItemsChanged] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);
    const [cartTotalQuantity, setCartTotalQuantity] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const getCartItems = async () => {
            if (!token) {
                return;
            }
            const res = await axios.get(`https://api.detacheg.com/api/cart/user/${token}`);
            setCartItems(res?.data?.items);
        };
        getCartItems();
    }, [cartItemsChanged]);

    useEffect(() => {
        const calculateTotal = () => {
            let total = 0;
            cartItems?.forEach(cartItem => {
                cartItem.items.forEach(item => {
                    total += item.priceAfterDiscount * item.quantity;
                });
            });
            setCartTotal(total);
        };

        calculateTotal();
    }, [cartItems]);


    //get the total quantity of items in the cart
    useEffect(() => {
        const calculateTotalQuantity = () => {
            let total = 0;
            cartItems?.forEach(cartItem => {
                cartItem?.items?.forEach(item => {
                    total += item.quantity;
                });
            });
            setCartTotalQuantity(total);
        };

        calculateTotalQuantity();
    }, [cartItems]);

    return (
        <AppContext.Provider value={{ cartItemsChanged, setCartItemsChanged, cartItems, setCartItems, cartTotal, setCartTotal, cartTotalQuantity, setCartTotalQuantity, setIsAdmin, isAdmin }}>
            {children}
        </AppContext.Provider>
    );
};
