import React from "react";
import { NewsCard } from "./Content/NewsCard";

export function News() {

    return (
        <>
            <NewsCard />
        </>
    );
}
export default News;