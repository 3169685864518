import Card from "react-bootstrap/Card";
import { Collapse, DatePicker, Form, Spin } from "antd";
import { useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

function CurrentShipmentsCard() {

  const [loading, setLoading] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalStatus, setTotalStatus] = useState([
    { Status: "Delivered", Count: 1 },
    { Status: "Undelivered", Count: 2 },
    { Status: "Out For Delivery", Count: 3 },
    { Status: "New", Count: 4 },
  ]);
  const [totalPaid, setTotalPaid] = useState([
    { isPaidDisplay: "True", Count: 1 },
    { isPaidDisplay: "False", Count: 2 },
  ]);

  const handleDateRangeChange = (dates) => {
    if (dates === null) {
      setFromDate(null);
      setToDate(null);
    } else {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    }
  };

  // const getAllStatus = async () => {
  //   setLoading(true);
  //   const response = await api.post(`status-count-by-subAccountID`,
  //     {
  //       fromDate: fromDate,
  //       toDate: toDate,
  //     }
  //   );
  //   setTotalStatus(response.data);
  //   setLoading(false);
  // };

  // const getTotalPaid = async () => {
  //   setLoading(true);
  //   const response = await api.post(`paid-not-paid-count-by-subAccountID`,
  //     {
  //       fromDate: fromDate,
  //       toDate: toDate,
  //     }
  //   );
  //   setTotalPaid(response.data);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   getAllStatus();
  // }, [fromDate, toDate]);

  // useEffect(() => {
  //   getTotalPaid();
  // }, [fromDate, toDate]);

  const deliveryCount = totalStatus.some(
    (status) => status.Status === "Out For Delivery" && status.Count > 0
  )
    ? totalStatus
      .filter(
        (status) => status.Status === "Out For Delivery" && status.Count > 0
      )
      .map((status) => status.Count)
    : [0];

  const newCount = totalStatus.some(
    (status) => status.Status === "New" && status.Count > 0
  )
    ? totalStatus
      .filter((status) => status.Status === "New" && status.Count > 0)
      .map((status) => status.Count)
    : [0];

  const inTransitCount = totalStatus.some(
    (status) => status.Status === "In Transit" && status.Count > 0
  )
    ? totalStatus
      .filter((status) => status.Status === "In Transit" && status.Count > 0)
      .map((status) => status.Count)
    : [0];

  // const outForReturnCount = totalStatus.some(
  //   (status) => status.Status === "Out for return" && status.Count > 0
  // )
  //   ? totalStatus
  //     .filter(
  //       (status) => status.Status === "Out for return" && status.Count > 0
  //     )
  //     .map((status) => status.Count)
  //   : [0];

  const onHoldCount = totalStatus.some(
    (status) => status.Status === "On Hold" && status.Count > 0
  )
    ? totalStatus
      .filter((status) => status.Status === "On Hold" && status.Count > 0)
      .map((status) => status.Count)
    : [0];

  // const returningToOriginCount = totalStatus.some(
  //   (status) => status.Status === "Returning to origin" && status.Count > 0
  // )
  //   ? totalStatus
  //     .filter(
  //       (status) =>
  //         status.Status === "Returning to origin" && status.Count > 0
  //     )
  //     .map((status) => status.Count)
  //   : [0];

  const paidCount = totalPaid.some(
    (paid) => paid.isPaidDisplay === "True" && paid.Count > 0
  )
    ? totalPaid
      .filter((paid) => paid.isPaidDisplay === "True" && paid.Count > 0)
      .map((paid) => paid.Count)
    : [0];

  const notPaidCount = totalPaid.some(
    (paid) => paid.isPaidDisplay === "False" && paid.Count > 0
  )
    ? totalPaid
      .filter((paid) => paid.isPaidDisplay === "False" && paid.Count > 0)
      .map((paid) => paid.Count)
    : [0];

  return (
    <Card style={{ width: "100%" }}>
      <Form.Item label="Filter" style={{ marginLeft: "200px", marginTop: "20px" }}>
        <RangePicker onChange={handleDateRangeChange} allowClear />
      </Form.Item>
      {/* <Card.Header>New Pickup</Card.Header> */}
      <Card.Body>
        <Spin tip="Loading" size="large" spinning={loading} indicator={<LoadingOutlined />}>
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="New Shipments" key="1">
              <ul>
                <li>
                  New{" "}
                  <div style={{ float: "right" }}>
                    {" "}
                    <a
                      href="/shipments"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {newCount} Shipments
                    </a>{" "}
                  </div>{" "}
                </li>
              </ul>
            </Panel>
            <Panel header="Processing Shipments" key="2">
              <ul>
                <li>
                  In transit{" "}
                  <div style={{ float: "right" }}>
                    {" "}
                    <a
                      href="/shipments"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {inTransitCount} Shipments
                    </a>{" "}
                  </div>{" "}
                </li>
                <li>
                  Out for delivery
                  <div style={{ float: "right" }}>
                    {" "}
                    <a
                      href="/shipments"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {deliveryCount} Shipments
                    </a>{" "}
                  </div>{" "}
                </li>
                {/* <li>
                  Out for return{" "}
                  <div style={{ float: "right" }}>
                    {" "}
                    <a
                      href="/shipments"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {outForReturnCount} Shipments
                    </a>{" "}
                  </div>{" "}
                </li>
                <li>
                  Returning to origin{" "}
                  <div style={{ float: "right" }}>
                    {" "}
                    <a
                      href="/shipments"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {returningToOriginCount} Shipments
                    </a>{" "}
                  </div>{" "}
                </li> */}
                {/* <li>
                Awaiting your action{" "}
                <div style={{ float: "right" }}>
                  {" "}
                  <a
                    href="/shipments"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    0 Shipments
                  </a>{" "}
                </div>{" "}
              </li> */}
                <li>
                  On Hold{" "}
                  <div style={{ float: "right" }}>
                    {" "}
                    <a
                      href="/shipments"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {onHoldCount} Shipments
                    </a>{" "}
                  </div>{" "}
                </li>
              </ul>
            </Panel>
            <Panel header="Paid Shipments" key="3">
              <ul>
                <li>
                  Paid
                  <div style={{ float: "right" }}> {paidCount} Shipments</div>
                </li>
                <li>
                  Not paid
                  <div style={{ float: "right" }}> {notPaidCount} Shipments</div>
                </li>
              </ul>
            </Panel>
          </Collapse>
        </Spin>
        <br />
      </Card.Body>
    </Card>
  );
}

export default CurrentShipmentsCard;
