import { useEffect, useState, useContext } from "react";
import { Divider, Input, Typography, Select, Button, message } from 'antd';
import { CreditCardOutlined, DollarOutlined } from '@ant-design/icons';
import './checkout.css';
import '../../App.css';
import CheckoutItem from "./checkoutItem";
import Footer from "../Footer/Footer";
import { AppContext } from "../../AppContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Checkout() {

    const { cartItems, cartTotal, setCartItems, cartItemsChanged, setCartItemsChanged } = useContext(AppContext);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [street, setStreet] = useState('');
    const [governorate, setGovernorate] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [billingCountry, setBillingCountry] = useState('');
    const [billingStreet, setBillingStreet] = useState('');
    const [billingGovernorate, setBillingGovernorate] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingPostalCode, setBillingPostalCode] = useState('');

    const [notes, setNotes] = useState('');

    const [shippingCost, setShippingCost] = useState(50)

    const [promoCode, setPromoCode] = useState('');
    const [totalDiscountType, setTotalDiscountType] = useState('');
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(cartTotal + shippingCost);


    useEffect(() => {
        if (cartItems.length === 0) {
            navigate('/');
        }
    }, [cartItems]);

    const { Title } = Typography;
    const [showDivider, setShowDivider] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setShowDivider(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to prevent memory leaks
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setShowDivider(window.innerWidth > 1000);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to prevent memory leaks
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [selectedPayment, setSelectedPayment] = useState('cash');

    const handlePaymentSelection = (paymentMethod) => {
        setSelectedPayment(paymentMethod);
    };

    const [selectedBillingAddress, setSelectedBillingAddress] = useState('same');

    const handleBillingAddressSelection = (billingAddress) => {
        setSelectedBillingAddress(billingAddress);
    };

    const [discountCode, setDiscountCode] = useState('')

    const renderCartItems = () => {
        return cartItems?.map(cartItem => {
            return cartItem?.items.map(item => (
                <CheckoutItem
                    productID={cartItem.product.productId}
                    subItemID={item.itemID}
                    image={`https://api.detacheg.com/api/images/getImage?name=${item.images[0]}`} // Assuming you want to display the first image
                    name={cartItem.product.name}
                    size={item.size}
                    color={item.color}
                    price={item.priceAfterDiscount}
                    quantity={item.quantity}
                />
            ));
        });
    };

    const [convertedCartItems, setConvertedCartItems] = useState([]);

    useEffect(() => {
        const convertCartItems = () => {
            const convertedItems = cartItems?.flatMap(cartItem =>
                cartItem.items.map(item => ({
                    productID: cartItem.product.productId,
                    productName: cartItem.product.name,
                    itemID: item.itemID,
                    size: item.size,
                    color: item.color,
                    quantity: item.quantity,
                    priceAfterDiscount: item.priceAfterDiscount
                }))
            );
            setConvertedCartItems(convertedItems);
        };

        convertCartItems();
    }, [cartItems]);

    const [isVoucherValid, setIsVoucherValid] = useState(false);

    const checkVoucher = async () => {
        try {
            const res = await axios.get(`https://api.detacheg.com/api/vouchers/code/${discountCode}`);
            if (res.data === "Voucher not found") {
                message.error("Invalid Voucher Code");
                setIsVoucherValid(false);
                setPromoCode('');
                setTotalDiscountType('');
                setTotalDiscount(0);
                setTotalPriceAfterDiscount(cartTotal + shippingCost);
            } else {
                message.success("Voucher Applied Successfully");
                setIsVoucherValid(true);
                setPromoCode(discountCode);
                setTotalDiscountType(res.data.discountType);
                setTotalDiscount(res.data.discount);
                setTotalPriceAfterDiscount(res.data.discountType === 'Percentage' ? cartTotal * (1 - res.data.discount / 100) + shippingCost : res.data.discountType === 'Fixed' ? cartTotal - res.data.discount + shippingCost : cartTotal + shippingCost);
            }
        } catch (error) {
        }
    }

    const createOrder = async () => {
        try {
            if (email === '' || country === '' || firstName === '' || lastName === '' || street === '' || governorate === '' || city === '' || phoneNumber === '') {
                message.error('Please fill all the required fields');
                return;
            }
            if (selectedBillingAddress === 'different' && (billingCountry === '' || billingStreet === '' || billingGovernorate === '' || billingCity === '')) {
                message.error('Please fill all the required fields');
                return;
            }
            const order = {
                customerName: `${firstName} ${lastName}`,
                customerEmail: email,
                customerPhone: phoneNumber,
                copmanyName: company,
                shippingAddress: {
                    country: country,
                    governorate: governorate,
                    city: city,
                    street: street,
                    postalCode: postalCode,
                },
                billingAddress: {
                    country: selectedBillingAddress === 'same' ? country : billingCountry,
                    governorate: selectedBillingAddress === 'same' ? governorate : billingGovernorate,
                    city: selectedBillingAddress === 'same' ? city : billingCity,
                    street: selectedBillingAddress === 'same' ? street : billingStreet,
                    postalCode: selectedBillingAddress === 'same' ? postalCode : billingPostalCode,
                },
                shippingCost: shippingCost,
                promoCode: promoCode,
                paymentMethod: 'cash',
                totalPrice: cartTotal + shippingCost,
                totalDiscountType: totalDiscountType,
                totalDiscount: totalDiscount,
                totalPriceAfterDiscount: totalPriceAfterDiscount,
                items: convertedCartItems,
                notes: notes
            };

            const res = await axios.post('https://api.detacheg.com/api/orders', order);
            if (res.status === 200) {
                await axios.delete(`https://api.detacheg.com/api/cart/${localStorage.getItem('token')}`);
                message.success('Order Placed Successfully');
                setCartItems([]);
                setCartItemsChanged(!cartItemsChanged);
                navigate('/');
            }
            else {
                message.error('An error occurred while placing the order, please try again later!');
            }
            // navigate('/');
        } catch (error) {
            message.error('An error occurred while placing the order, please try again later!');
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <Divider style={{ backgroundColor: "black" }} />
            <div style={{
                display: "flex",
                justifyContent: showDivider ? "center" : "flex-start",
                height: "100% !important",
                flexDirection: showDivider ? 'row' : 'column',
                alignItems: showDivider ? "flex-start" : "center"
            }}>
                <div className="leftCheckout">

                    <div style={{ width: "100%" }}>
                        <Title className="Title" level={2}>Delivery</Title>
                        <Title className="Title" level={5}>Email</Title>
                        <Input placeholder="Email" className="Inputs" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div style={{ width: "100%" }}>
                        <Title className="Title" level={5}>Country/Region</Title>
                        <Select placeholder="Country/Region" style={{ width: "100%" }} options={[
                            { value: "Afghanistan", label: "Afghanistan" },
                            { value: "Albania", label: "Albania" },
                            { value: "Algeria", label: "Algeria" },
                            { value: "Andorra", label: "Andorra" },
                            { value: "Angola", label: "Angola" },
                            { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
                            { value: "Argentina", label: "Argentina" },
                            { value: "Armenia", label: "Armenia" },
                            { value: "Australia", label: "Australia" },
                            { value: "Austria", label: "Austria" },
                            { value: "Azerbaijan", label: "Azerbaijan" },
                            { value: "Bahamas", label: "Bahamas" },
                            { value: "Bahrain", label: "Bahrain" },
                            { value: "Bangladesh", label: "Bangladesh" },
                            { value: "Barbados", label: "Barbados" },
                            { value: "Belarus", label: "Belarus" },
                            { value: "Belgium", label: "Belgium" },
                            { value: "Belize", label: "Belize" },
                            { value: "Benin", label: "Benin" },
                            { value: "Bhutan", label: "Bhutan" },
                            { value: "Bolivia", label: "Bolivia" },
                            { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
                            { value: "Botswana", label: "Botswana" },
                            { value: "Brazil", label: "Brazil" },
                            { value: "Brunei", label: "Brunei" },
                            { value: "Bulgaria", label: "Bulgaria" },
                            { value: "Burkina Faso", label: "Burkina Faso" },
                            { value: "Burundi", label: "Burundi" },
                            { value: "Cabo Verde", label: "Cabo Verde" },
                            { value: "Cambodia", label: "Cambodia" },
                            { value: "Cameroon", label: "Cameroon" },
                            { value: "Canada", label: "Canada" },
                            { value: "Central African Republic", label: "Central African Republic" },
                            { value: "Chad", label: "Chad" },
                            { value: "Chile", label: "Chile" },
                            { value: "China", label: "China" },
                            { value: "Colombia", label: "Colombia" },
                            { value: "Comoros", label: "Comoros" },
                            { value: "Congo, Democratic Republic of the", label: "Congo, Democratic Republic of the" },
                            { value: "Congo, Republic of the", label: "Congo, Republic of the" },
                            { value: "Costa Rica", label: "Costa Rica" },
                            { value: "Cote d'Ivoire", label: "Cote d'Ivoire" },
                            { value: "Croatia", label: "Croatia" },
                            { value: "Cuba", label: "Cuba" },
                            { value: "Cyprus", label: "Cyprus" },
                            { value: "Czech Republic", label: "Czech Republic" },
                            { value: "Denmark", label: "Denmark" },
                            { value: "Djibouti", label: "Djibouti" },
                            { value: "Dominica", label: "Dominica" },
                            { value: "Dominican Republic", label: "Dominican Republic" },
                            { value: "East Timor", label: "East Timor" },
                            { value: "Ecuador", label: "Ecuador" },
                            { value: "Egypt", label: "Egypt" },
                            { value: "El Salvador", label: "El Salvador" },
                            { value: "Equatorial Guinea", label: "Equatorial Guinea" },
                            { value: "Eritrea", label: "Eritrea" },
                            { value: "Estonia", label: "Estonia" },
                            { value: "Eswatini", label: "Eswatini" },
                            { value: "Ethiopia", label: "Ethiopia" },
                            { value: "Fiji", label: "Fiji" },
                            { value: "Finland", label: "Finland" },
                            { value: "France", label: "France" },
                            { value: "Gabon", label: "Gabon" },
                            { value: "Gambia", label: "Gambia" },
                            { value: "Georgia", label: "Georgia" },
                            { value: "Germany", label: "Germany" },
                            { value: "Ghana", label: "Ghana" },
                            { value: "Greece", label: "Greece" },
                            { value: "Grenada", label: "Grenada" },
                            { value: "Guatemala", label: "Guatemala" },
                            { value: "Guinea", label: "Guinea" },
                            { value: "Guinea-Bissau", label: "Guinea-Bissau" },
                            { value: "Guyana", label: "Guyana" },
                            { value: "Haiti", label: "Haiti" },
                            { value: "Honduras", label: "Honduras" },
                            { value: "Hungary", label: "Hungary" },
                            { value: "Iceland", label: "Iceland" },
                            { value: "India", label: "India" },
                            { value: "Indonesia", label: "Indonesia" },
                            { value: "Iran", label: "Iran" },
                            { value: "Iraq", label: "Iraq" },
                            { value: "Ireland", label: "Ireland" },
                            { value: "Israel", label: "Israel" },
                            { value: "Italy", label: "Italy" },
                            { value: "Jamaica", label: "Jamaica" },
                            { value: "Japan", label: "Japan" },
                            { value: "Jordan", label: "Jordan" },
                            { value: "Kazakhstan", label: "Kazakhstan" },
                            { value: "Kenya", label: "Kenya" },
                            { value: "Kiribati", label: "Kiribati" },
                            { value: "Korea, North", label: "Korea, North" },
                            { value: "Korea, South", label: "Korea, South" },
                            { value: "Kosovo", label: "Kosovo" },
                            { value: "Kuwait", label: "Kuwait" },
                            { value: "Kyrgyzstan", label: "Kyrgyzstan" },
                            { value: "Laos", label: "Laos" },
                            { value: "Latvia", label: "Latvia" },
                            { value: "Lebanon", label: "Lebanon" },
                            { value: "Lesotho", label: "Lesotho" },
                            { value: "Liberia", label: "Liberia" },
                            { value: "Libya", label: "Libya" },
                            { value: "Liechtenstein", label: "Liechtenstein" },
                            { value: "Lithuania", label: "Lithuania" },
                            { value: "Luxembourg", label: "Luxembourg" },
                            { value: "Madagascar", label: "Madagascar" },
                            { value: "Malawi", label: "Malawi" },
                            { value: "Malaysia", label: "Malaysia" },
                            { value: "Maldives", label: "Maldives" },
                            { value: "Mali", label: "Mali" },
                            { value: "Malta", label: "Malta" },
                            { value: "Marshall Islands", label: "Marshall Islands" },
                            { value: "Mauritania", label: "Mauritania" },
                            { value: "Mauritius", label: "Mauritius" },
                            { value: "Mexico", label: "Mexico" },
                            { value: "Micronesia", label: "Micronesia" },
                            { value: "Moldova", label: "Moldova" },
                            { value: "Monaco", label: "Monaco" },
                            { value: "Mongolia", label: "Mongolia" },
                            { value: "Montenegro", label: "Montenegro" },
                            { value: "Morocco", label: "Morocco" },
                            { value: "Mozambique", label: "Mozambique" },
                            { value: "Myanmar", label: "Myanmar" },
                            { value: "Namibia", label: "Namibia" },
                            { value: "Nauru", label: "Nauru" },
                            { value: "Nepal", label: "Nepal" },
                            { value: "Netherlands", label: "Netherlands" },
                            { value: "New Zealand", label: "New Zealand" },
                            { value: "Nicaragua", label: "Nicaragua" },
                            { value: "Niger", label: "Niger" },
                            { value: "Nigeria", label: "Nigeria" },
                            { value: "North Macedonia", label: "North Macedonia" },
                            { value: "Norway", label: "Norway" },
                            { value: "Oman", label: "Oman" },
                            { value: "Pakistan", label: "Pakistan" },
                            { value: "Palau", label: "Palau" },
                            { value: "Palestine", label: "Palestine" },
                            { value: "Panama", label: "Panama" },
                            { value: "Papua New Guinea", label: "Papua New Guinea" },
                            { value: "Paraguay", label: "Paraguay" },
                            { value: "Peru", label: "Peru" },
                            { value: "Philippines", label: "Philippines" },
                            { value: "Poland", label: "Poland" },
                            { value: "Portugal", label: "Portugal" },
                            { value: "Qatar", label: "Qatar" },
                            { value: "Romania", label: "Romania" },
                            { value: "Russia", label: "Russia" },
                            { value: "Rwanda", label: "Rwanda" },
                            { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
                            { value: "Saint Lucia", label: "Saint Lucia" },
                            { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
                            { value: "Samoa", label: "Samoa" },
                            { value: "San Marino", label: "San Marino" },
                            { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
                            { value: "Saudi Arabia", label: "Saudi Arabia" },
                            { value: "Senegal", label: "Senegal" },
                            { value: "Serbia", label: "Serbia" },
                            { value: "Seychelles", label: "Seychelles" },
                            { value: "Sierra Leone", label: "Sierra Leone" },
                            { value: "Singapore", label: "Singapore" },
                            { value: "Slovakia", label: "Slovakia" },
                            { value: "Slovenia", label: "Slovenia" },
                            { value: "Solomon Islands", label: "Solomon Islands" },
                            { value: "Somalia", label: "Somalia" },
                            { value: "South Africa", label: "South Africa" },
                            { value: "South Sudan", label: "South Sudan" },
                            { value: "Spain", label: "Spain" },
                            { value: "Sri Lanka", label: "Sri Lanka" },
                            { value: "Sudan", label: "Sudan" },
                            { value: "Suriname", label: "Suriname" },
                            { value: "Sweden", label: "Sweden" },
                            { value: "Switzerland", label: "Switzerland" },
                            { value: "Syria", label: "Syria" },
                            { value: "Taiwan", label: "Taiwan" },
                            { value: "Tajikistan", label: "Tajikistan" },
                            { value: "Tanzania", label: "Tanzania" },
                            { value: "Thailand", label: "Thailand" },
                            { value: "Togo", label: "Togo" },
                            { value: "Tonga", label: "Tonga" },
                            { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
                            { value: "Tunisia", label: "Tunisia" },
                            { value: "Turkey", label: "Turkey" },
                            { value: "Turkmenistan", label: "Turkmenistan" },
                            { value: "Tuvalu", label: "Tuvalu" },
                            { value: "Uganda", label: "Uganda" },
                            { value: "Ukraine", label: "Ukraine" },
                            { value: "United Arab Emirates", label: "United Arab Emirates" },
                            { value: "United Kingdom", label: "United Kingdom" },
                            { value: "United States", label: "United States" },
                            { value: "Uruguay", label: "Uruguay" },
                            { value: "Uzbekistan", label: "Uzbekistan" },
                            { value: "Vanuatu", label: "Vanuatu" },
                            { value: "Vatican City", label: "Vatican City" },
                            { value: "Venezuela", label: "Venezuela" },
                            { value: "Vietnam", label: "Vietnam" },
                            { value: "Yemen", label: "Yemen" },
                            { value: "Zambia", label: "Zambia" },
                            { value: "Zimbabwe", label: "Zimbabwe" }
                        ]}
                            onChange={(value) => {
                                setCountry(value);
                            }}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", gap: "20px" }}>
                        <div style={{ width: "100%" }}>
                            <Title className="Title" level={5}>First Name</Title>
                            <Input placeholder="First Name" className="Inputs" onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Title className="Title" level={5}>Last Name</Title>
                            <Input placeholder="Last Name" className="Inputs" onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ width: "100%" }}>
                        <Title className="Title" level={5}>{"Company (optional)"}</Title>
                        <Input placeholder="Company" className="Inputs" onChange={(e) => setCompany(e.target.value)} />
                    </div>
                    <div style={{ width: "100%" }}>
                        <Title className="Title" level={5}>Street</Title>
                        <Input placeholder="Street" className="Inputs" onChange={(e) => setStreet(e.target.value)} />
                    </div>
                    <div style={{ width: "100%", display: "flex", gap: "20px" }}>
                        <div style={{ width: "100%" }}>
                            <Title className="Title" level={5}>Governorate</Title>
                            <Select placeholder="Governorate" style={{ width: "100%" }} options={[
                                { value: "Alexandria", label: "Alexandria" },
                                { value: "Aswan", label: "Aswan" },
                                { value: "Asyut", label: "Asyut" },
                                { value: "Beheira", label: "Beheira" },
                                { value: "Beni Suef", label: "Beni Suef" },
                                { value: "Cairo", label: "Cairo" },
                                { value: "Dakahlia", label: "Dakahlia" },
                                { value: "Damietta", label: "Damietta" },
                                { value: "Faiyum", label: "Faiyum" },
                                { value: "Gharbia", label: "Gharbia" },
                                { value: "Giza", label: "Giza" },
                                { value: "Ismailia", label: "Ismailia" },
                                { value: "Kafr El Sheikh", label: "Kafr El Sheikh" },
                                { value: "Luxor", label: "Luxor" },
                                { value: "Matrouh", label: "Matrouh" },
                                { value: "Minya", label: "Minya" },
                                { value: "Monufia", label: "Monufia" },
                                { value: "New Valley", label: "New Valley" },
                                { value: "North Sinai", label: "North Sinai" },
                                { value: "Port Said", label: "Port Said" },
                                { value: "Qalyubia", label: "Qalyubia" },
                                { value: "Qena", label: "Qena" },
                                { value: "Red Sea", label: "Red Sea" },
                                { value: "Sharqia", label: "Sharqia" },
                                { value: "Sohag", label: "Sohag" },
                                { value: "South Sinai", label: "South Sinai" },
                                { value: "Suez", label: "Suez" }
                            ]}
                                onChange={(value) => {
                                    setGovernorate(value);
                                }}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Title className="Title" level={5}>City</Title>
                            <Select placeholder="City" style={{ width: "100%" }} options={[
                                { value: "Downtown Cairo", label: "Downtown Cairo" },
                                { value: "Zamalek", label: "Zamalek" },
                                { value: "Maadi", label: "Maadi" },
                                { value: "Heliopolis", label: "Heliopolis" },
                                { value: "New Cairo", label: "New Cairo" },
                                { value: "6th of October City", label: "6th of October City" },
                                { value: "Nasr City", label: "Nasr City" },
                                { value: "Sharm El Sheikh", label: "Sharm El Sheikh" },
                                { value: "Hurghada", label: "Hurghada" },
                                { value: "Dahab", label: "Dahab" },
                                { value: "El Gouna", label: "El Gouna" },
                                { value: "Ain Sokhna", label: "Ain Sokhna" },
                                { value: "Sahl Hasheesh", label: "Sahl Hasheesh" },
                                { value: "Marsa Alam", label: "Marsa Alam" },
                                { value: "Nuweiba", label: "Nuweiba" },
                                { value: "Ras Sedr", label: "Ras Sedr" },
                                { value: "El Alamein", label: "El Alamein" },
                                { value: "El Fayoum", label: "El Fayoum" },
                                { value: "El Minya", label: "El Minya" },
                                { value: "Kafr El Sheikh", label: "Kafr El Sheikh" }
                            ]}
                                onChange={(value) => {
                                    setCity(value);
                                }}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Title className="Title" level={5}>Postal Code</Title>
                            <Input placeholder="Postal Code" className="Inputs" onChange={(e) => setPostalCode(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ width: "100%" }}>
                        <Title className="Title" level={5}>Phone Number</Title>
                        <Input placeholder="Phone Number" className="Inputs" onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                        <Title className="Title" level={2}>Payment</Title>
                        <Title className="Title" level={5} style={{ color: "#ccc", marginTop: "0" }}>All transactions are secure and encrypted.</Title>
                        <div className="payment-options">
                            {/* <div
                                className={`payment-option ${selectedPayment === 'visa' ? 'selectedType' : ''}`}
                                onClick={() => handlePaymentSelection('visa')}
                            >
                                <CreditCardOutlined />
                                <h3>Pay via (Debit/Credit cards/Wallets/Installments)</h3>
                            </div> */}
                            <div
                                className={`payment-option ${selectedPayment === 'cash' ? 'selectedType' : ''}`}
                                onClick={() => handlePaymentSelection('cash')}
                            >
                                <DollarOutlined />
                                <h3>Cash on Delivery (COD)</h3>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                        <Title className="Title" level={2}>Billing Address</Title>
                        <div className="billing-addresses">
                            <div
                                className={`billing-address ${selectedBillingAddress === 'same' ? 'selectedType' : ''}`}
                                onClick={() => handleBillingAddressSelection('same')}
                            >
                                <h3>Same as shipping address</h3>
                            </div>
                            <div
                                className={`billing-address ${selectedBillingAddress === 'different' ? 'selectedType' : ''}`}
                                onClick={() => handleBillingAddressSelection('different')}
                            >
                                <h3>Use a different billing address</h3>
                            </div>
                        </div>
                        {
                            selectedBillingAddress === 'different' &&
                            <div>
                                <div style={{ width: "100%" }}>
                                    <Title className="Title" level={5}>Country/Region</Title>
                                    <Select placeholder="Country/Region" style={{ width: "100%" }} options={[
                                        { value: "Afghanistan", label: "Afghanistan" },
                                        { value: "Albania", label: "Albania" },
                                        { value: "Algeria", label: "Algeria" },
                                        { value: "Andorra", label: "Andorra" },
                                        { value: "Angola", label: "Angola" },
                                        { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
                                        { value: "Argentina", label: "Argentina" },
                                        { value: "Armenia", label: "Armenia" },
                                        { value: "Australia", label: "Australia" },
                                        { value: "Austria", label: "Austria" },
                                        { value: "Azerbaijan", label: "Azerbaijan" },
                                        { value: "Bahamas", label: "Bahamas" },
                                        { value: "Bahrain", label: "Bahrain" },
                                        { value: "Bangladesh", label: "Bangladesh" },
                                        { value: "Barbados", label: "Barbados" },
                                        { value: "Belarus", label: "Belarus" },
                                        { value: "Belgium", label: "Belgium" },
                                        { value: "Belize", label: "Belize" },
                                        { value: "Benin", label: "Benin" },
                                        { value: "Bhutan", label: "Bhutan" },
                                        { value: "Bolivia", label: "Bolivia" },
                                        { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
                                        { value: "Botswana", label: "Botswana" },
                                        { value: "Brazil", label: "Brazil" },
                                        { value: "Brunei", label: "Brunei" },
                                        { value: "Bulgaria", label: "Bulgaria" },
                                        { value: "Burkina Faso", label: "Burkina Faso" },
                                        { value: "Burundi", label: "Burundi" },
                                        { value: "Cabo Verde", label: "Cabo Verde" },
                                        { value: "Cambodia", label: "Cambodia" },
                                        { value: "Cameroon", label: "Cameroon" },
                                        { value: "Canada", label: "Canada" },
                                        { value: "Central African Republic", label: "Central African Republic" },
                                        { value: "Chad", label: "Chad" },
                                        { value: "Chile", label: "Chile" },
                                        { value: "China", label: "China" },
                                        { value: "Colombia", label: "Colombia" },
                                        { value: "Comoros", label: "Comoros" },
                                        { value: "Congo, Democratic Republic of the", label: "Congo, Democratic Republic of the" },
                                        { value: "Congo, Republic of the", label: "Congo, Republic of the" },
                                        { value: "Costa Rica", label: "Costa Rica" },
                                        { value: "Cote d'Ivoire", label: "Cote d'Ivoire" },
                                        { value: "Croatia", label: "Croatia" },
                                        { value: "Cuba", label: "Cuba" },
                                        { value: "Cyprus", label: "Cyprus" },
                                        { value: "Czech Republic", label: "Czech Republic" },
                                        { value: "Denmark", label: "Denmark" },
                                        { value: "Djibouti", label: "Djibouti" },
                                        { value: "Dominica", label: "Dominica" },
                                        { value: "Dominican Republic", label: "Dominican Republic" },
                                        { value: "East Timor", label: "East Timor" },
                                        { value: "Ecuador", label: "Ecuador" },
                                        { value: "Egypt", label: "Egypt" },
                                        { value: "El Salvador", label: "El Salvador" },
                                        { value: "Equatorial Guinea", label: "Equatorial Guinea" },
                                        { value: "Eritrea", label: "Eritrea" },
                                        { value: "Estonia", label: "Estonia" },
                                        { value: "Eswatini", label: "Eswatini" },
                                        { value: "Ethiopia", label: "Ethiopia" },
                                        { value: "Fiji", label: "Fiji" },
                                        { value: "Finland", label: "Finland" },
                                        { value: "France", label: "France" },
                                        { value: "Gabon", label: "Gabon" },
                                        { value: "Gambia", label: "Gambia" },
                                        { value: "Georgia", label: "Georgia" },
                                        { value: "Germany", label: "Germany" },
                                        { value: "Ghana", label: "Ghana" },
                                        { value: "Greece", label: "Greece" },
                                        { value: "Grenada", label: "Grenada" },
                                        { value: "Guatemala", label: "Guatemala" },
                                        { value: "Guinea", label: "Guinea" },
                                        { value: "Guinea-Bissau", label: "Guinea-Bissau" },
                                        { value: "Guyana", label: "Guyana" },
                                        { value: "Haiti", label: "Haiti" },
                                        { value: "Honduras", label: "Honduras" },
                                        { value: "Hungary", label: "Hungary" },
                                        { value: "Iceland", label: "Iceland" },
                                        { value: "India", label: "India" },
                                        { value: "Indonesia", label: "Indonesia" },
                                        { value: "Iran", label: "Iran" },
                                        { value: "Iraq", label: "Iraq" },
                                        { value: "Ireland", label: "Ireland" },
                                        { value: "Israel", label: "Israel" },
                                        { value: "Italy", label: "Italy" },
                                        { value: "Jamaica", label: "Jamaica" },
                                        { value: "Japan", label: "Japan" },
                                        { value: "Jordan", label: "Jordan" },
                                        { value: "Kazakhstan", label: "Kazakhstan" },
                                        { value: "Kenya", label: "Kenya" },
                                        { value: "Kiribati", label: "Kiribati" },
                                        { value: "Korea, North", label: "Korea, North" },
                                        { value: "Korea, South", label: "Korea, South" },
                                        { value: "Kosovo", label: "Kosovo" },
                                        { value: "Kuwait", label: "Kuwait" },
                                        { value: "Kyrgyzstan", label: "Kyrgyzstan" },
                                        { value: "Laos", label: "Laos" },
                                        { value: "Latvia", label: "Latvia" },
                                        { value: "Lebanon", label: "Lebanon" },
                                        { value: "Lesotho", label: "Lesotho" },
                                        { value: "Liberia", label: "Liberia" },
                                        { value: "Libya", label: "Libya" },
                                        { value: "Liechtenstein", label: "Liechtenstein" },
                                        { value: "Lithuania", label: "Lithuania" },
                                        { value: "Luxembourg", label: "Luxembourg" },
                                        { value: "Madagascar", label: "Madagascar" },
                                        { value: "Malawi", label: "Malawi" },
                                        { value: "Malaysia", label: "Malaysia" },
                                        { value: "Maldives", label: "Maldives" },
                                        { value: "Mali", label: "Mali" },
                                        { value: "Malta", label: "Malta" },
                                        { value: "Marshall Islands", label: "Marshall Islands" },
                                        { value: "Mauritania", label: "Mauritania" },
                                        { value: "Mauritius", label: "Mauritius" },
                                        { value: "Mexico", label: "Mexico" },
                                        { value: "Micronesia", label: "Micronesia" },
                                        { value: "Moldova", label: "Moldova" },
                                        { value: "Monaco", label: "Monaco" },
                                        { value: "Mongolia", label: "Mongolia" },
                                        { value: "Montenegro", label: "Montenegro" },
                                        { value: "Morocco", label: "Morocco" },
                                        { value: "Mozambique", label: "Mozambique" },
                                        { value: "Myanmar", label: "Myanmar" },
                                        { value: "Namibia", label: "Namibia" },
                                        { value: "Nauru", label: "Nauru" },
                                        { value: "Nepal", label: "Nepal" },
                                        { value: "Netherlands", label: "Netherlands" },
                                        { value: "New Zealand", label: "New Zealand" },
                                        { value: "Nicaragua", label: "Nicaragua" },
                                        { value: "Niger", label: "Niger" },
                                        { value: "Nigeria", label: "Nigeria" },
                                        { value: "North Macedonia", label: "North Macedonia" },
                                        { value: "Norway", label: "Norway" },
                                        { value: "Oman", label: "Oman" },
                                        { value: "Pakistan", label: "Pakistan" },
                                        { value: "Palau", label: "Palau" },
                                        { value: "Palestine", label: "Palestine" },
                                        { value: "Panama", label: "Panama" },
                                        { value: "Papua New Guinea", label: "Papua New Guinea" },
                                        { value: "Paraguay", label: "Paraguay" },
                                        { value: "Peru", label: "Peru" },
                                        { value: "Philippines", label: "Philippines" },
                                        { value: "Poland", label: "Poland" },
                                        { value: "Portugal", label: "Portugal" },
                                        { value: "Qatar", label: "Qatar" },
                                        { value: "Romania", label: "Romania" },
                                        { value: "Russia", label: "Russia" },
                                        { value: "Rwanda", label: "Rwanda" },
                                        { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
                                        { value: "Saint Lucia", label: "Saint Lucia" },
                                        { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
                                        { value: "Samoa", label: "Samoa" },
                                        { value: "San Marino", label: "San Marino" },
                                        { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
                                        { value: "Saudi Arabia", label: "Saudi Arabia" },
                                        { value: "Senegal", label: "Senegal" },
                                        { value: "Serbia", label: "Serbia" },
                                        { value: "Seychelles", label: "Seychelles" },
                                        { value: "Sierra Leone", label: "Sierra Leone" },
                                        { value: "Singapore", label: "Singapore" },
                                        { value: "Slovakia", label: "Slovakia" },
                                        { value: "Slovenia", label: "Slovenia" },
                                        { value: "Solomon Islands", label: "Solomon Islands" },
                                        { value: "Somalia", label: "Somalia" },
                                        { value: "South Africa", label: "South Africa" },
                                        { value: "South Sudan", label: "South Sudan" },
                                        { value: "Spain", label: "Spain" },
                                        { value: "Sri Lanka", label: "Sri Lanka" },
                                        { value: "Sudan", label: "Sudan" },
                                        { value: "Suriname", label: "Suriname" },
                                        { value: "Sweden", label: "Sweden" },
                                        { value: "Switzerland", label: "Switzerland" },
                                        { value: "Syria", label: "Syria" },
                                        { value: "Taiwan", label: "Taiwan" },
                                        { value: "Tajikistan", label: "Tajikistan" },
                                        { value: "Tanzania", label: "Tanzania" },
                                        { value: "Thailand", label: "Thailand" },
                                        { value: "Togo", label: "Togo" },
                                        { value: "Tonga", label: "Tonga" },
                                        { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
                                        { value: "Tunisia", label: "Tunisia" },
                                        { value: "Turkey", label: "Turkey" },
                                        { value: "Turkmenistan", label: "Turkmenistan" },
                                        { value: "Tuvalu", label: "Tuvalu" },
                                        { value: "Uganda", label: "Uganda" },
                                        { value: "Ukraine", label: "Ukraine" },
                                        { value: "United Arab Emirates", label: "United Arab Emirates" },
                                        { value: "United Kingdom", label: "United Kingdom" },
                                        { value: "United States", label: "United States" },
                                        { value: "Uruguay", label: "Uruguay" },
                                        { value: "Uzbekistan", label: "Uzbekistan" },
                                        { value: "Vanuatu", label: "Vanuatu" },
                                        { value: "Vatican City", label: "Vatican City" },
                                        { value: "Venezuela", label: "Venezuela" },
                                        { value: "Vietnam", label: "Vietnam" },
                                        { value: "Yemen", label: "Yemen" },
                                        { value: "Zambia", label: "Zambia" },
                                        { value: "Zimbabwe", label: "Zimbabwe" }
                                    ]}
                                        onChange={(value) => {
                                            setBillingCountry(value);
                                        }}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <Title className="Title" level={5}>Street</Title>
                                    <Input placeholder="Street" className="Inputs" onChange={(e) => setBillingStreet(e.target.value)} />
                                </div>
                                <div style={{ width: "100%", display: "flex", gap: "20px" }}>
                                    <div style={{ width: "100%" }}>
                                        <Title className="Title" level={5}>Governorate</Title>
                                        <Select placeholder="Governorate" style={{ width: "100%" }} options={[
                                            { value: "Alexandria", label: "Alexandria" },
                                            { value: "Aswan", label: "Aswan" },
                                            { value: "Asyut", label: "Asyut" },
                                            { value: "Beheira", label: "Beheira" },
                                            { value: "Beni Suef", label: "Beni Suef" },
                                            { value: "Cairo", label: "Cairo" },
                                            { value: "Dakahlia", label: "Dakahlia" },
                                            { value: "Damietta", label: "Damietta" },
                                            { value: "Faiyum", label: "Faiyum" },
                                            { value: "Gharbia", label: "Gharbia" },
                                            { value: "Giza", label: "Giza" },
                                            { value: "Ismailia", label: "Ismailia" },
                                            { value: "Kafr El Sheikh", label: "Kafr El Sheikh" },
                                            { value: "Luxor", label: "Luxor" },
                                            { value: "Matrouh", label: "Matrouh" },
                                            { value: "Minya", label: "Minya" },
                                            { value: "Monufia", label: "Monufia" },
                                            { value: "New Valley", label: "New Valley" },
                                            { value: "North Sinai", label: "North Sinai" },
                                            { value: "Port Said", label: "Port Said" },
                                            { value: "Qalyubia", label: "Qalyubia" },
                                            { value: "Qena", label: "Qena" },
                                            { value: "Red Sea", label: "Red Sea" },
                                            { value: "Sharqia", label: "Sharqia" },
                                            { value: "Sohag", label: "Sohag" },
                                            { value: "South Sinai", label: "South Sinai" },
                                            { value: "Suez", label: "Suez" }
                                        ]}
                                            onChange={(value) => {
                                                setBillingGovernorate(value);
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Title className="Title" level={5}>City</Title>
                                        <Select placeholder="City" style={{ width: "100%" }} options={[
                                            { value: "Downtown Cairo", label: "Downtown Cairo" },
                                            { value: "Zamalek", label: "Zamalek" },
                                            { value: "Maadi", label: "Maadi" },
                                            { value: "Heliopolis", label: "Heliopolis" },
                                            { value: "New Cairo", label: "New Cairo" },
                                            { value: "6th of October City", label: "6th of October City" },
                                            { value: "Nasr City", label: "Nasr City" },
                                            { value: "Sharm El Sheikh", label: "Sharm El Sheikh" },
                                            { value: "Hurghada", label: "Hurghada" },
                                            { value: "Dahab", label: "Dahab" },
                                            { value: "El Gouna", label: "El Gouna" },
                                            { value: "Ain Sokhna", label: "Ain Sokhna" },
                                            { value: "Sahl Hasheesh", label: "Sahl Hasheesh" },
                                            { value: "Marsa Alam", label: "Marsa Alam" },
                                            { value: "Nuweiba", label: "Nuweiba" },
                                            { value: "Ras Sedr", label: "Ras Sedr" },
                                            { value: "El Alamein", label: "El Alamein" },
                                            { value: "El Fayoum", label: "El Fayoum" },
                                            { value: "El Minya", label: "El Minya" },
                                            { value: "Kafr El Sheikh", label: "Kafr El Sheikh" }
                                        ]}
                                            onChange={(value) => {
                                                setBillingCity(value);
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Title className="Title" level={5}>Postal Code</Title>
                                        <Input placeholder="Postal Code" className="Inputs" onChange={(e) => setBillingPostalCode(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
                        <Title className="Title" level={2}>Notes</Title>
                        <Input.TextArea placeholder="Notes" className="Inputs" onChange={(e) => setNotes(e.target.value)} />
                    </div>
                </div>
                <div className="rightCheckout">
                    <Title className="Title" level={2}>Order Summary</Title>
                    <div className="checkoutItemsContainer">
                        {
                            renderCartItems()
                        }
                    </div>
                    <div style={{ width: "100%" }}>
                        <Title className="Title" level={5}>Discount Code</Title>
                        <div style={{ width: "100%", display: "flex", gap: "5px" }}>
                            <Input placeholder="Discount Code" className="Inputs" style={{ width: "75%" }} onChange={(e) => setDiscountCode(e.target.value)} />
                            <Button className="submitBTN" type="primary" style={{ fontSize: "20px", width: "25%", height: "40px", backgroundColor: "black", fontFamily: "'Roboto', sans-serif", fontWeight: "bold" }} disabled={!discountCode} onClick={checkVoucher}>
                                Apply
                            </Button>
                        </div>

                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                        <Title className="Title" style={{ margin: "0" }} level={5}>Subtotal</Title>
                        <Title className="Title" style={{ margin: "0" }} level={5}>EGP {cartTotal}</Title>
                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                        <Title className="Title" style={{ margin: "0" }} level={5}>Shipping</Title>
                        <Title className="Title" style={{ margin: "0" }} level={5}>EGP {shippingCost}</Title>
                    </div>
                    {
                        isVoucherValid &&
                        <>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                                <Title className="Title" style={{ margin: "0" }} level={3}>Total before discount</Title>
                                <Title className="Title" style={{ margin: "0" }} level={3}>EGP {cartTotal + shippingCost}</Title>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                                <Title className="Title" style={{ margin: "0" }} level={5}>Discount</Title>
                                <Title className="Title" style={{ margin: "0" }} level={5}>{totalDiscountType === 'Percentage' ? `${totalDiscount}%` : `EGP ${totalDiscount}`}</Title>
                            </div>
                        </>
                    }
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                        <Title className="Title" style={{ margin: "0" }} level={3}>Total</Title>
                        <Title className="Title" style={{ margin: "0" }} level={3}>EGP {totalDiscountType === 'Percentage' ? (cartTotal + shippingCost) * (1 - totalDiscount / 100) : totalDiscountType === 'Fixed' ? cartTotal + shippingCost - totalDiscount : cartTotal + shippingCost}</Title>
                    </div>
                    <div style={{ width: "100%", marginBottom: "50px" }}>
                        <Button className="submitBTN" type="primary" style={{ fontSize: "20px", width: "100%", marginTop: "20px", height: "50px", backgroundColor: "black", fontFamily: "'Roboto', sans-serif", fontWeight: "bold" }} onClick={createOrder}>
                            {
                                selectedPayment === 'visa' ? 'Pay Now' : 'Complete Order'
                            }
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}