import React, { useState } from "react";
import logo from "../../assets/images/BlackLogo.png";
// import "antd/dist/antd.min.css";
import { Button, Menu, Spin } from "antd";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import SpeakerOutlinedIcon from "@mui/icons-material/SpeakerOutlined";
import SpeakerGroupOutlinedIcon from "@mui/icons-material/SpeakerGroupOutlined";
import LanguageIcon from '@mui/icons-material/Language';
import BusinessIcon from '@mui/icons-material/Business';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PaymentIcon from '@mui/icons-material/Payment';
import {
  HomeOutlined,
  SaveOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DropboxOutlined,
  LogoutOutlined,
  BranchesOutlined,
  UserOutlined,
  TeamOutlined,
  SettingOutlined,
  DollarOutlined,
  LoadingOutlined,
  ProductOutlined,
  OrderedListOutlined,
  MoneyCollectOutlined,
  HomeFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import style from "./Sidebar.module.css";
import { jwtDecode } from "jwt-decode";
import { useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";
import axios from "axios";
import { Newspaper } from "@mui/icons-material";


function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Sidebar = () => {

  const [loading, setLoading] = useState(false);

  const [current, setCurrent] = useState("/dashboard");
  const [user, setUserInfo] = useState({
    name: "",
    email: ""
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = jwtDecode(token);
      setUserInfo(user);
    }
  }, []);
  const navigate = useNavigate();

  const [sidebarItems, setSidebarItems] = useState([
    // getItem("Dashboard", "/dashboard", <HomeOutlined />),
    getItem("Products", "/products", <ProductOutlined />),
    getItem("Orders", "/orders", <OrderedListOutlined />),
    getItem("Collections", "/collections", <ProductOutlined />),
    getItem("Vouchers", "/vouchers", <MoneyCollectOutlined />),
    getItem("News", "/news", <Newspaper />),
    getItem("Website", "/home", <HomeFilled />),
    getItem("Subscribers", "/subscribers", <TeamOutlined />),
    getItem("Logout", "/logout", <LogoutOutlined />),
  ]);

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
    if (e.key === "/logout") {
      localStorage.clear();
      navigate("/login");
    }
  };


  useEffect(() => {
    const getUserDetails = async () => {
      setLoading(true);
      const res = await axios.get(`/users-with-info-client`);
      setUserInfo(res.data[0]);
      setLoading(false);
    };
    // getUserDetails();
  }, []);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    var div = document.getElementById("content");
    if (div.style.marginLeft === "80px") {
      div.style.marginLeft = "250px";
    } else {
      div.style.marginLeft = "80px";
    }
  };

  return (
    <div
      style={{ borderRight: "solid 1px #e4e7ec" }}
      className={collapsed ? style.sidebarcollapsed : style.sidebar}
    >
      {collapsed ? (
        <>
          <div className={style.sidebar__logocollapsed}>
            <img alt="" src={logo} />
          </div>
          <div className={style.sidebar__userinfocollapsed}>
            <div>
              <img
                alt="Avatar"
                style={{ borderRadius: "50%" }}
                src="https://cdn-icons-png.flaticon.com/512/15/15081.png"
              />
            </div>
          </div>
          <hr style={{ opacity: "10%" }} />
        </>
      ) : (
        <>
          <div className={style.sidebar__logo}>
            <img alt="logo" src={logo} />
          </div>
          <Spin tip="Loading" size="large" spinning={loading} indicator={<LoadingOutlined />}>
            <div className={style.sidebar__userinfo}>
              <div>
                <img
                  alt="Avatar"
                  style={{ borderRadius: "50%" }}
                  src="https://cdn-icons-png.flaticon.com/512/15/15081.png"
                />
              </div>
              <div className={style.sidebar__userinfo__text}>
                <span
                  title={user.name}
                  className={style.sidebar__userinfo__text__name}
                >
                  {user.name}
                </span>
                <span
                  title={user.email}
                  className={style.sidebar__userinfo__text__email}
                >
                  {user.email}
                </span>
              </div>
            </div>
          </Spin>
          <hr style={{ opacity: "10%" }} />
        </>
      )}
      <div className={style.centered}>
        <Button
          type="primary"
          onClick={toggleCollapsed}
          className={style.collapsebutton}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <Menu
        theme="light"
        onClick={onClick}
        className={style.sidebar__menu}
        // defaultOpenKeys={['sub1']}
        selectedKeys={[current]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={sidebarItems.length > 0 ? sidebarItems : []}
      />
    </div>
  );
};

export default Sidebar;
