import React, { useEffect, useState } from "react";
import {
  ColorPicker,
  Table,
  Button,
  Popconfirm,
  Modal,
  Form,
  Input,
  Image,
  Upload,
  message,
  Card,
  Space,
  Select,
  InputNumber,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./AddProduct.css";
import { style } from "@mui/system";
import axios from "axios";

export function Products() {

  const [isLoading, setIsLoading] = useState(true);

  const [isUpdated, setIsUpdated] = useState(false);

  const [colorHex, setColorHex] = useState('#1677ff');
  const [formatHex, setFormatHex] = useState('hex');
  const hexString = React.useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
    [colorHex],
  );

  const [products, setProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const [form] = Form.useForm();

  const [productData, setProductData] = useState({
    name: "",
    longDescription: [],
    collection: "",
    items: [],
  });

  const validateProductData = (productData) => {
    for (const item of productData.items) {
      const sizeSet = new Set();
      for (const subItem of item.subItems) {
        if (sizeSet.has(subItem.size)) {
          return `Duplicate size "${subItem.size}" found in subItems for color "${item.color}".`;
        }
        sizeSet.add(subItem.size);
      }
    }
    return null;
  };

  const addProduct = async () => {
    // Ensure each item has a color key
    const updatedProductData = {
      ...productData,
      items: productData.items.map(item => ({
        ...item,
        color: item.color || "#1677ff"
      }))
    };
  
    const validationError = validateProductData(updatedProductData);
    if (validationError) {
      console.error(validationError);
      message.error(validationError);
      return;
    }
  
    try {
      await axios.post("https://api.detacheg.com/api/products", updatedProductData);
      setIsUpdated(!isUpdated);
    } catch (err) {
      console.error(err);
    }
  };
  

  useEffect(() => {
    const getProducts = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get("https://api.detacheg.com/api/products");
        setProducts(res.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    };
    getProducts();
  }, [isUpdated]);

  const handleOk = () => {
    addProduct();
    setIsModalVisible(false);
  };


  const showModal = () => {
    setIsModalVisible(true);
    setEditProductId(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditProductId(null);
  };


  const removeProduct = async (productId) => {
    try {
      await axios.delete(`https://api.detacheg.com/api/products/${productId}`);
      setIsUpdated(!isUpdated);
    }
    catch (err) {
      console.error(err);
    }
  };

  const editProduct = (productId) => {
    const productToEdit = products.find((product) => product._id === productId);
    if (productToEdit) {
      form.setFieldsValue({
        name: productToEdit.name,
        description: productToEdit.description,
        price: productToEdit.price,
        salePrice: productToEdit.SalePrice,
        quantity: productToEdit.quantity,
      });
      setIsModalVisible(true);
      setEditProductId(productId);
    }
  };

  const [descriptionModalVisible, setDescriptionModalVisible] = useState(null);
  const [itemsModalVisible, setItemsModalVisible] = useState(null);

  const showDescriptionModal = (id) => {
    setDescriptionModalVisible(id);
  };

  const showItemsModal = (id) => {
    setItemsModalVisible(id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "longDescription",
      key: "longDescription",
      render: (description, record) => (
        <>
          <Button type="primary" onClick={() => showDescriptionModal(record._id)}>
            View Description
          </Button>
          <Modal
            title="Description"
            open={descriptionModalVisible === record._id}
            footer={[]}
            onCancel={() => setDescriptionModalVisible(null)}
          >
            {description &&
              description.map((item) => (
                <Card title={item.title} style={{ marginBottom: "10px" }}>
                  <ul>
                    {item.shortDescription.map((desc) => (
                      <li>{desc}</li>
                    ))}
                  </ul>
                </Card>
              ))}
          </Modal>
        </>
      ),
    },
    {
      title: "Collection",
      dataIndex: "collection",
      key: "collection",
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      render: (items, record) => (
        <>
          <Button type="primary" onClick={() => showItemsModal(record._id)}>
            View Items
          </Button>
          <Modal
            title="Items"
            open={itemsModalVisible === record._id}
            footer={[]}
            onCancel={() => setItemsModalVisible(null)}
          >
            {items &&
              items.map((item) => (
                <Card title={`Color: ${item.color}`} style={{ marginBottom: "10px" }}>
                  <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", marginBottom: "10px", alignItems: "center", justifyContent: "center" }}>
                    {item.images.map((image) => (
                      <Image width={70} height={100} src={`https://api.detacheg.com/api/images/getImage?name=${image}`} alt="Product Image" />
                    ))}
                  </div>
                  {item.subItems.map((subItem) => (
                    <Card title={`Size ${subItem.size}`} style={{ marginBottom: "10px" }}>
                      <ul>
                        <li>Quantity: {subItem.quantity}</li>
                        <li>Weight: {subItem.weight}</li>
                        <li>Price: {subItem.price}</li>
                        <li>Discount Type: {subItem.discountType}</li>
                        <li>Discount: {subItem.discount}</li>
                        <li>Price After Discount: {subItem.priceAfterDiscount}</li>
                      </ul>
                    </Card>
                  ))}
                </Card>
              ))}
          </Modal>
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Popconfirm
            title="Are you sure you want to remove this product?"
            placement="right"
            onConfirm={() => removeProduct(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="small"
              style={{ backgroundColor: "red", color: "white" }}
            />
          </Popconfirm>
          <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => editProduct(record._id)}
          />
        </>
      ),
    },
  ];

  const props = (fieldIndex) => ({
    name: "files",
    multiple: true,
    action: "https://api.detacheg.com/api/images/multiple",
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        const allDone = info.fileList.every(file => file.status === "done");

        if (allDone) {
          setProductData((prevState) => {
            const items = [...prevState.items];

            if (!items[fieldIndex]) {
              items[fieldIndex] = { images: [] };
            }

            items[fieldIndex].images = info.fileList.map((file) => file.response.uploadedFiles[0]);

            return { ...prevState, items };
          });
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  });

  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const getCollections = async () => {
      const res = await axios.get("https://api.detacheg.com/api/collections");
      const collections = res.data.map((collection) => ({
        label: collection.name,
        value: collection.name,
      }));
      setCollections(collections);
    }
    getCollections();
  }, []);


  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginBottom: "20px" }}
        onClick={showModal}
      >
        Add Product
      </Button>
      <Modal
        title="Add Product"
        width={1000}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={style["product-modal"]}
      >
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleOk}
        >
          <Form.Item
            label={<span style={{ color: "white" }}>Name</span>}
            name="name"
            rules={[{ required: true, message: "Please enter Name" }]}
            initialValue={
              editProductId
                ? products.find((product) => product._id === editProductId).name
                : ""
            }
          >
            <Input
              onChange={(e) =>
                setProductData({ ...productData, name: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Description</span>}
            name="descriptionItem"
            initialValue={
              editProductId
                ? products.find((product) => product._id === editProductId)
                  .longDescription
                : ""
            }
          >
            <Form.List name="description">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field, fieldIndex) => {
                    const longDescriptionItem =
                      productData.longDescription &&
                      productData.longDescription[fieldIndex];
                    return (
                      <Card
                        size="small"
                        title={`Description ${field.name + 1}`}
                        key={field.key}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                              setProductData((prevState) => ({
                                ...prevState,
                                longDescription:
                                  prevState.longDescription.filter(
                                    (_, index) => index !== fieldIndex
                                  ),
                              }));
                            }}
                          />
                        }
                      >
                        <Form.Item label="Title" name={[field.name, "name"]} initialValue={
                          editProductId
                            ? products.find((product) => product._id === editProductId).longDescription[fieldIndex].title
                            : ""
                        }
                        >
                          <Input
                            onChange={(e) => {
                              const title = e.target.value;
                              setProductData((prevState) => {
                                const longDescription = [
                                  ...(prevState.longDescription || []),
                                ];
                                if (!longDescription[fieldIndex]) {
                                  longDescription[fieldIndex] = {
                                    title: "",
                                    shortDescription: [],
                                  };
                                }
                                longDescription[fieldIndex].title = title;
                                return { ...prevState, longDescription };
                              });
                            }}
                            defaultValue={
                              editProductId
                                ? products.find((product) => product._id === editProductId).longDescription[fieldIndex].title
                                : ""
                            }
                          />
                        </Form.Item>

                        {/* Nest Form.List */}
                        <Form.Item label="Bullets" name={[field.name, "list"]} initialValue={
                          editProductId
                            ? products.find((product) => product._id === editProductId).longDescription[fieldIndex].shortDescription
                            : ""
                        }>
                          <Form.List name={[field.name, "list"]}>
                            {(subFields, subOpt) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: 16,
                                }}
                              >
                                {subFields.map((subField, subFieldIndex) => (
                                  <Space key={subField.key}>
                                    <Form.Item
                                      noStyle
                                      name={[subField.name, "second"]}
                                      initialValue={
                                        editProductId
                                          ? products.find((product) => product._id === editProductId).longDescription[fieldIndex].shortDescription[subFieldIndex]
                                          : ""
                                      }
                                    >
                                      <Input
                                        onChange={(e) => {
                                          const bullet = e.target.value;
                                          setProductData((prevState) => {
                                            const longDescription = [
                                              ...(prevState.longDescription ||
                                                []),
                                            ];
                                            if (!longDescription[fieldIndex]) {
                                              longDescription[fieldIndex] = {
                                                title: "",
                                                shortDescription: [],
                                              };
                                            }
                                            const shortDescription = [
                                              ...longDescription[fieldIndex]
                                                .shortDescription,
                                            ];
                                            shortDescription[subFieldIndex] =
                                              bullet;
                                            longDescription[
                                              fieldIndex
                                            ].shortDescription =
                                              shortDescription;
                                            return {
                                              ...prevState,
                                              longDescription,
                                            };
                                          });
                                        }}
                                        defaultValue={
                                          editProductId
                                            ? products.find((product) => product._id === editProductId).longDescription[fieldIndex].shortDescription[subFieldIndex]
                                            : ""
                                        }
                                      />
                                    </Form.Item>
                                    <CloseOutlined
                                      onClick={() => {
                                        subOpt.remove(subField.name);
                                        setProductData((prevState) => {
                                          const longDescription = [
                                            ...(prevState.longDescription ||
                                              []),
                                          ];
                                          const shortDescription =
                                            longDescription[
                                              fieldIndex
                                            ].shortDescription.filter(
                                              (_, index) =>
                                                index !== subFieldIndex
                                            );
                                          longDescription[
                                            fieldIndex
                                          ].shortDescription = shortDescription;
                                          return {
                                            ...prevState,
                                            longDescription,
                                          };
                                        });
                                      }}
                                    />
                                  </Space>
                                ))}
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    subOpt.add();
                                    setProductData((prevState) => {
                                      const longDescription = [
                                        ...(prevState.longDescription || []),
                                      ];
                                      if (!longDescription[fieldIndex]) {
                                        longDescription[fieldIndex] = {
                                          title: "",
                                          shortDescription: [],
                                        };
                                      }
                                      longDescription[
                                        fieldIndex
                                      ].shortDescription.push("");
                                      return { ...prevState, longDescription };
                                    });
                                  }}
                                  block
                                >
                                  + Add Bullet
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      </Card>
                    );
                  })}
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                      setProductData((prevState) => ({
                        ...prevState,
                        longDescription: [
                          ...(prevState.longDescription || []),
                          { title: "", shortDescription: [] },
                        ],
                      }));
                    }}
                    block
                  >
                    + Add Description
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>

          <Form.Item
            label={<span style={{ color: "white" }}>Collection</span>}
            name="collection"
            rules={[{ required: true, message: "Please choose collection" }]}
            initialValue={
              editProductId
                ? products.find((product) => product._id === editProductId).price
                : ""
            }
          >
            <Select
              placeholder="Select a collection"
              allowClear
              options={collections}
              style={{ marginBottom: "10px" }}
              onChange={(value) =>
                setProductData({ ...productData, collection: value })
              }
              defaultValue={
                editProductId
                  ? products.find((product) => product._id === editProductId)
                    .collection
                  : ""
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Items</span>}
            name="itemsItem"
            initialValue={
              editProductId
                ? products.find((product) => product._id === editProductId)
                  .description
                : ""
            }
          >
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      title={`Item ${field.name + 1}`}
                      key={field.key}
                      style={{ width: "100%" }}
                      extra={
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                            setProductData((prevState) => {
                              const items = [...prevState.items];
                              items.splice(field.name, 1);
                              return { ...prevState, items };
                            });
                          }}
                        />
                      }
                    >
                      <Form.Item label="Color" name={[field.name, "color"]}>
                        <Space>
                          <ColorPicker
                            format={formatHex}
                            value={colorHex}
                            onChange={(value, hex) => {
                              setColorHex(hex);
                              setProductData((prevState) => {
                                const items = [...prevState.items];

                                if (!items[field.name]) {
                                  items[field.name] = {};
                                }

                                items[field.name].color = hex;

                                return { ...prevState, items };
                              })
                            }
                            }
                            onFormatChange={setFormatHex}
                          />
                          <span>HEX: {hexString}</span>
                        </Space>
                      </Form.Item>
                      <Form.Item label="Images" name={[field.name, "images"]}>
                        <Upload {...props(field.name)} >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                      <Form.Item label="SubItems" style={{ width: "100%" }}>
                        <Form.List name={[field.name, "subItems"]}>
                          {(subFields, subOpt) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                                width: "100%",
                              }}
                            >
                              {subFields.map((subField) => (
                                <Card
                                  size="small"
                                  title={`SubItem ${subField.name + 1}`}
                                  key={subField.key}
                                  style={{ width: "100%" }}
                                  extra={
                                    <CloseOutlined
                                      onClick={() => {
                                        subOpt.remove(subField.name);
                                        setProductData((prevState) => {
                                          const items = [...prevState.items];

                                          if (!items[field.name]) {
                                            items[field.name] = {};
                                          }

                                          if (!items[field.name].subItems) {
                                            items[field.name].subItems = [];
                                          }

                                          if (!items[field.name].subItems[subField.name]) {
                                            items[field.name].subItems[subField.name] = {};
                                          }

                                          items[field.name].subItems.splice(
                                            subField.name,
                                            1
                                          );
                                          return { ...prevState, items };
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Space
                                    key={subField.key}
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                      }}
                                    >
                                      <Form.Item
                                        label="Size"
                                        name={[subField.name, "size"]}
                                      >
                                        <Select
                                          placeholder="Select a size"
                                          allowClear
                                          options={[
                                            { label: "XS", value: "xs" },
                                            { label: "S", value: "s" },
                                            { label: "M", value: "m" },
                                            { label: "L", value: "l" },
                                            { label: "XL", value: "xl" },
                                          ]}
                                          onChange={
                                            (value) =>
                                              setProductData((prevState) => {
                                                const items = [...prevState.items];

                                                if (!items[field.name]) {
                                                  items[field.name] = {};
                                                }

                                                if (!items[field.name].subItems) {
                                                  items[field.name].subItems = [];
                                                }

                                                if (!items[field.name].subItems[subField.name]) {
                                                  items[field.name].subItems[subField.name] = {};
                                                }

                                                items[field.name].subItems[
                                                  subField.name
                                                ].size = value;

                                                return { ...prevState, items };
                                              })
                                          }
                                          style={{
                                            marginBottom: "10px",
                                            width: "100%",
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Quantity"
                                        name={[subField.name, "quantity"]}
                                      >
                                        <InputNumber
                                          style={{ width: "100%" }}
                                          onChange={
                                            (value) =>
                                              setProductData((prevState) => {
                                                const items = [...prevState.items];

                                                if (!items[field.name]) {
                                                  items[field.name] = {};
                                                }

                                                if (!items[field.name].subItems) {
                                                  items[field.name].subItems = [];
                                                }

                                                if (!items[field.name].subItems[subField.name]) {
                                                  items[field.name].subItems[subField.name] = {};
                                                }

                                                items[field.name].subItems[
                                                  subField.name
                                                ].quantity = value;

                                                return { ...prevState, items };
                                              })
                                          }
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Weight"
                                        name={[subField.name, "weight"]}
                                      >
                                        <InputNumber
                                          style={{ width: "100%" }}
                                          onChange={
                                            (value) =>
                                              setProductData((prevState) => {
                                                const items = [...prevState.items];

                                                if (!items[field.name]) {
                                                  items[field.name] = {};
                                                }

                                                if (!items[field.name].subItems) {
                                                  items[field.name].subItems = [];
                                                }

                                                if (!items[field.name].subItems[subField.name]) {
                                                  items[field.name].subItems[subField.name] = {};
                                                }

                                                items[field.name].subItems[
                                                  subField.name
                                                ].weight = value;

                                                return { ...prevState, items };
                                              })
                                          }
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        label="Price"
                                        name={[subField.name, "price"]}
                                      >
                                        <InputNumber
                                          style={{ width: "100%" }}
                                          onChange={
                                            (value) =>
                                              setProductData((prevState) => {
                                                const items = [...prevState.items];
                                                if (!items[field.name]) {
                                                  items[field.name] = {};
                                                }

                                                if (!items[field.name].subItems) {
                                                  items[field.name].subItems = [];
                                                }

                                                if (!items[field.name].subItems[subField.name]) {
                                                  items[field.name].subItems[subField.name] = {};
                                                }

                                                items[field.name].subItems[
                                                  subField.name
                                                ].price = value;

                                                return { ...prevState, items };
                                              })
                                          }
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        label="Discount Type"
                                        name={[subField.name, "discountType"]}
                                      >
                                        <Select
                                          placeholder="Select a discount type"
                                          allowClear
                                          options={[
                                            {
                                              label: "Percentage",
                                              value: "percentage",
                                            },
                                            { label: "Fixed", value: "fixed" },
                                          ]}
                                          style={{ marginBottom: "10px" }}
                                          onChange={
                                            (value) =>
                                              setProductData((prevState) => {
                                                const items = [...prevState.items];
                                                if (!items[field.name]) {
                                                  items[field.name] = {};
                                                }
                                                if (!items[field.name].subItems) {
                                                  items[field.name].subItems = [];
                                                }

                                                if (!items[field.name].subItems[subField.name]) {
                                                  items[field.name].subItems[subField.name] = {};
                                                }
                                                items[field.name].subItems[
                                                  subField.name
                                                ].discountType = value;

                                                return { ...prevState, items };
                                              })
                                          }
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        label="Discount"
                                        name={[subField.name, "discount"]}
                                      >
                                        <InputNumber
                                          style={{ width: "100%" }}
                                          onChange={
                                            (value) =>
                                              setProductData((prevState) => {
                                                const items = [...prevState.items];

                                                if (!items[field.name]) {
                                                  items[field.name] = {};
                                                }

                                                if (!items[field.name].subItems) {
                                                  items[field.name].subItems = [];
                                                }

                                                if (!items[field.name].subItems[subField.name]) {
                                                  items[field.name].subItems[subField.name] = {};
                                                }

                                                items[field.name].subItems[
                                                  subField.name
                                                ].discount = value;

                                                return { ...prevState, items };
                                              })
                                          }
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        label="Price After Discount"
                                        name={[
                                          subField.name,
                                          "priceAfterDiscount",
                                        ]}
                                      >
                                        {
                                          // Solve this error Cannot read properties of undefined (reading '0')
                                          <span>
                                            {productData?.items?.[field.name]?.subItems?.[subField.name]?.discountType === "percentage" &&
                                              productData.items[field.name].subItems[subField.name].price &&
                                              productData.items[field.name].subItems[subField.name].discount &&
                                              productData.items[field.name].subItems[subField.name].price -
                                              (productData.items[field.name].subItems[subField.name].price *
                                                productData.items[field.name].subItems[subField.name].discount) / 100}
                                            {productData?.items?.[field.name]?.subItems?.[subField.name]?.discountType === "fixed" &&
                                              productData.items[field.name].subItems[subField.name].price &&
                                              productData.items[field.name].subItems[subField.name].discount &&
                                              productData.items[field.name].subItems[subField.name].price -
                                              productData.items[field.name].subItems[subField.name].discount}
                                          </span>
                                        }
                                      </Form.Item>
                                    </div>
                                  </Space>
                                </Card>
                              ))}
                              <Button
                                type="dashed"
                                onClick={() => subOpt.add()}
                                block
                              >
                                + Add SubItem
                              </Button>
                            </div>
                          )}
                        </Form.List>
                      </Form.Item>
                    </Card>
                  ))}

                  <Button type="dashed" onClick={() => add()} block>
                    + Add Item
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        dataSource={products}
        columns={columns}
        pagination={{ pageSize: 5 }}
        className="product-table"
        loading={isLoading}
      />
    </>
  );
}
