import '../../App.css';
import Background from '../background/background';
import Header from '../header/header';
import { useState, useEffect } from 'react';
import Products from '../products/products';
import Footer from '../Footer/Footer.jsx';
import { Modal, Input, Button, Spin } from 'antd';
import axios from 'axios';

export default function Home() {


    const [email, setEmail] = useState('');

    const addSubscriber = async () => {
        try {
            await axios.post('https://api.detacheg.com/api/subscribe', {
                email: email
            });
            localStorage.setItem('subscribed', true);
        } catch (error) {
            console.error(error);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleOk = () => {
        addSubscriber();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <div className='content'>
            <Modal
                title="Subscribe"
                open={localStorage.getItem('subscribed') ? false : isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button key="submit" onClick={handleOk} style={{ width: "35%", height: "auto" }}>
                            <span style={{ fontSize: "20px", textTransform: "uppercase", fontFamily: "'Roboto', sans-serif" }}>Subscribe</span>
                        </Button>
                    </div>
                ]}
            >
                <div className="subscribe-modal-content">
                    <p style={{ fontFamily: "'Roboto', sans-serif" }}>Stay up-to-date with our latest news and updates!</p>
                    <Input placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)} style={{ width: "100%", marginBottom: "20px" }} />
                </div>
            </Modal>
            <Background>
                <div className="content">
                    <Products />
                    <Footer />
                </div>
            </Background>
        </div>
    )
}