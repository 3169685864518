import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Modal,
  Form,
  Input,
  Image,
  Upload,
  message,
  Card,
  Space,
  Select,
  InputNumber,
} from "antd"; // Import necessary components
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InboxOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./Orders.css";
import { style, width } from "@mui/system";
import axios from "axios";

export function Orders() {

  const [isLoading, setIsLoading] = useState(true);

  // Use orderPage for clarity
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editOrderId, setEditOrderId] = useState(null);
  const [form] = Form.useForm();

  const showModal = () => {
    form.resetFields();
    setIsModalVisible(true);
    setEditOrderId(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditOrderId(null);
  };

  const handleOk = () => {
    if (editOrderId) {
      updateOrder();
    } else {
      addOrder();
    }
    setIsModalVisible(false);
  };

  const removeOrder = async (orderId) => {
    await axios.delete(`https://api.detacheg.com/api/orders/${orderId}`);
    setIsUpdated(!isUpdated);
  };

  const getOrderStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "Cancelled":
        return "red";
      case "Pending":
        return "blue";
      case "Out For delivery":
        return "green";
      default:
        return "gray"; // Default color for unknown statuses
    }
  };
  const editOrder = (orderId) => {
    const orderToEdit = orders.find((order) => order._id === orderId);
    if (orderToEdit) {
      form.setFieldsValue({
        _id: editOrderId,
        customerName: orderData.customerName,
        customerEmail: orderData.customerEmail,
        customerPhone: orderData.customerPhone,
        companyName: orderData.companyName,
        shippingAddress: {
          country: orderData.shippingAddress.country,
          governorate: orderData.shippingAddress.governorate,
          city: orderData.shippingAddress.city,
          street: orderData.shippingAddress.street,
          postalCode: orderData.shippingAddress.postalCode,
        },
        billingAddress: {
          country: orderData.billingAddress.country,
          governorate: orderData.billingAddress.governorate,
          city: orderData.billingAddress.city,
          street: orderData.billingAddress.street,
          postalCode: orderData.billingAddress.postalCode,
        },
        shippingCost: orderData.shippingCost,
        promoCode: orderData.promoCode,
        paymentMethod: orderData.paymentMethod,
        totalPrice: orderData.totalPrice,
        totalDiscountType: orderData.totalDiscountType,
        totalDiscount: orderData.totalDiscount,
        totalPriceAfterDiscount: orderData.totalPriceAfterDiscount,
        // items: orderData.items,
        status: orderData.status,
      });
      setOrderData({
        _id: editOrderId,
        customerName: orderData.customerName,
        customerEmail: orderData.customerEmail,
        customerPhone: orderData.customerPhone,
        companyName: orderData.companyName,
        shippingAddress: {
          country: orderData.shippingAddress.country,
          governorate: orderData.shippingAddress.governorate,
          city: orderData.shippingAddress.city,
          street: orderData.shippingAddress.street,
          postalCode: orderData.shippingAddress.postalCode,
        },
        billingAddress: {
          country: orderData.billingAddress.country,
          governorate: orderData.billingAddress.governorate,
          city: orderData.billingAddress.city,
          street: orderData.billingAddress.street,
          postalCode: orderData.billingAddress.postalCode,
        },
        shippingCost: orderData.shippingCost,
        promoCode: orderData.promoCode,
        paymentMethod: orderData.paymentMethod,
        totalPrice: orderData.totalPrice,
        totalDiscountType: orderData.totalDiscountType,
        totalDiscount: orderData.totalDiscount,
        totalPriceAfterDiscount: orderData.totalPriceAfterDiscount,
        // items: orderData.items,
        status: orderData.status,
      });
      setIsModalVisible(true);
      setEditOrderId(orderId);
    }
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      ellipsis: true, // Enable ellipsis for long descriptions
    },
    {
      title: "Customer Phone",
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Shipping Address",
      dataIndex: "shippingAddress",
      key: "shippingAddress",
      render: (shippingAddress) =>
        `${shippingAddress?.country || "N/A"}, ${
          shippingAddress?.governorate || "N/A"
        }, ${shippingAddress?.city || "N/A"}, ${
          shippingAddress?.street || "N/A"
        }, ${shippingAddress?.postalCode || "N/A"}`,
    },
    {
      title: "Billing Address",
      dataIndex: "billingAddress",
      key: "billingAddress",
      render: (billingAddress) =>
        `${billingAddress?.country || "N/A"}, ${
          billingAddress?.governorate || "N/A"
        }, ${billingAddress?.city || "N/A"}, ${
          billingAddress?.street || "N/A"
        }, ${billingAddress?.postalCode || "N/A"}`,
    },
    {
      title: "Shipping Cost",
      dataIndex: "shippingCost",
      key: "shippingCost",
      render: (shippingCost) => `EGP ${shippingCost.toFixed(2)}`,
    },
    {
      title: "Promo Code",
      dataIndex: "promoCode",
      key: "promoCode",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: "Total Discount Type",
      dataIndex: "totalDiscountType",
      key: "totalDiscountType",
    },
    {
      title: "Total Discount",
      dataIndex: "totalDiscount",
      key: "totalDiscount",
    },
    {
      title: "Total Price After Discount",
      dataIndex: "totalPriceAfterDiscount",
      key: "totalPriceAfterDiscount",
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      render: (items) =>
        items
          .map((item) => `${item.productName}, ${item.color}, ${item.size}, (${item.quantity})`)
          .join(", "),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (orderStatus) => (
        <span
          style={{
            backgroundColor: getOrderStatusColor(orderStatus),
            color: "white",
            color: "white",
            padding: "5px 10px",
            borderRadius: "4px",
          }}
        >
          {orderStatus}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Popconfirm
            title="Are you sure you want to remove this order?"
            placement="right"
            onConfirm={() => removeOrder(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="small"
              style={{ backgroundColor: "red", color: "white" }}
            />
          </Popconfirm>
          <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => editOrder(record._id)}
          />
        </>
      ),
    },
  ];

  const updateOrder = async () => {
    const res = await axios.put(`https://api.detacheg.com/api/orders`, {
      _id: editOrderId,
      customerName: orderData.customerName,
      customerEmail: orderData.customerEmail,
      customerPhone: orderData.customerPhone,
      companyName: orderData.companyName,
      shippingAddress: {
        country: orderData.shippingAddress.country,
        governorate: orderData.shippingAddress.governorate,
        city: orderData.shippingAddress.city,
        street: orderData.shippingAddress.street,
        postalCode: orderData.shippingAddress.postalCode,
      },
      billingAddress: {
        country: orderData.billingAddress.country,
        governorate: orderData.billingAddress.governorate,
        city: orderData.billingAddress.city,
        street: orderData.billingAddress.street,
        postalCode: orderData.billingAddress.postalCode,
      },
      shippingCost: orderData.shippingCost,
      promoCode: orderData.promoCode,
      paymentMethod: orderData.paymentMethod,
      totalPrice: orderData.totalPrice,
      totalDiscountType: orderData.totalDiscountType,
      totalDiscount: orderData.totalDiscount,
      totalPriceAfterDiscount: orderData.totalPriceAfterDiscount,
      items: orderData.items,
      status: orderData.status,
    });
    setIsUpdated(!isUpdated);
    form.resetFields();
    setOrderData({
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      companyName: "",
      shippingAddress: {
        country: "",
        governorate: "",
        city: "",
        street: "",
        postalCode: "",
      },
      billingAddress: {
        country: "",
        governorate: "",
        city: "",
        street: "",
        postalCode: "",
      },
      shippingCost: 0,
      promoCode: "",
      paymentMethod: "",
      totalPrice: 0,
      totalDiscountType: "",
      totalDiscount: 0,
      totalPriceAfterDiscount: 0,
      items: {
        productId: "",
        productName: "",
        itemID: "",
        size: "",
        color: "",
        quantity: 0,
        priceAfterDiscount: 0,
      },
      status: "",
    });
    setEditOrderId(null);
  };

  const [orderData, setOrderData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    companyName: "",
    shippingAddress: {
      country: "",
      governorate: "",
      city: "",
      street: "",
      postalCode: "",
    },
    billingAddress: {
      country: "",
      governorate: "",
      city: "",
      street: "",
      postalCode: "",
    },
    shippingCost: 0,
    promoCode: "",
    paymentMethod: "",
    items: [],
  });

  const [orders, setOrders] = useState([]);

  const [getProductsNames, setProductsNames] = useState([]);

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const getOrders = async () => {
      setIsLoading(true);
      const res = await axios.get("https://api.detacheg.com/api/orders");
      setOrders(res.data);
      setIsLoading(false);
    };
    getOrders();
  }, [isUpdated]);

  useEffect(() => {
    const getProductsNames = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get("https://api.detacheg.com/api/products");
        const names = res.data.map((product) => ({
          label: product.name,
          value: product.name,
        }));
        setProductsNames(names);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching product names:", error);
      }
    };
    getProductsNames();
  }, [isUpdated]);

  const addOrder = async () => {
    await axios.post("https://api.detacheg.com/api/orders", orderData);
    setIsUpdated(!isUpdated);
    // form.resetFields();
    // setOrderData({
    //   customerName: "",
    //   customerEmail: "",
    //   customerPhone: "",
    //   companyName: "",
    //   shippingAddress: {
    //     country: "",
    //     governorate: "",
    //     city: "",
    //     street: "",
    //     postalCode: "",
    //   },
    //   billingAddress: {
    //     country: "",
    //     governorate: "",
    //     city: "",
    //     street: "",
    //     postalCode: "",
    //   },
    //   shippingCost: 0,
    //   promoCode: "",
    //   paymentMethod: "",
    //   totalPrice: 0,
    //   totalDiscountType: "",
    //   totalDiscount: 0,
    //   totalPriceAfterDiscount: 0,
    //   items: [],
    // });
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginBottom: "20px" }}
        onClick={showModal}
      >
        Add Order
      </Button>
      <Modal
        title="Add Order"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={style["order-modal"]}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleOk}
          form={form}
        >
          <Form.Item
            label={<span style={{ color: "white" }}>Customer Name</span>}
            name="customerName"
            rules={[{ required: true, message: "Please enter Customer Name" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId).customerName
                : ""
            }
          >
            <Input
              onChange={(e) =>
                setOrderData({ ...orderData, customerName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Customer Email</span>}
            name="customerEmail"
            rules={[{ required: true, message: "Please enter Customer Email" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId)
                    .customerEmail
                : ""
            }
          >
            <Input
              onChange={(e) =>
                setOrderData({ ...orderData, customerEmail: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Customer Phone</span>}
            name="customerPhone"
            rules={[{ required: true, message: "Please enter Customer Phone" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId)
                    .customerPhone
                : ""
            }
          >
            <Input
              onChange={(e) =>
                setOrderData({ ...orderData, customerPhone: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Copmany Name</span>}
            name="companyName"
            rules={[{ required: true, message: "Please enter Copmany Name" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId).companyName
                : ""
            }
          >
            <Input
              onChange={(e) =>
                setOrderData({ ...orderData, companyName: e.target.value })
              }
            />
          </Form.Item>
          <div
            style={{ marginBottom: "20px", color: "white", fontWeight: "bold" }}
          >
            Shipping Address
          </div>
          <Form.Item
            label={<span style={{ color: "white" }}>Country</span>}
            name={["shippingAddress", "country"]}
            rules={[{ required: true, message: "Please enter country" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId).shippingAddress
                    .country
                : ""
            }
          >
            <Input
              value={orderData?.shippingAddress?.country}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  shippingAddress: {
                    ...orderData.shippingAddress,
                    country: e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Governorate</span>}
            name={["shippingAddress", "governorate"]}
            rules={[{ required: true, message: "Please enter governorate" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId).shippingAddress
                    .governorate
                : ""
            }
          >
            <Input
              value={orderData?.shippingAddress?.governorate}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  shippingAddress: {
                    ...orderData.shippingAddress,
                    governorate: e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>City</span>}
            name={["shippingAddress", "city"]}
            rules={[{ required: true, message: "Please enter city" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId).shippingAddress
                    .city
                : ""
            }
          >
            <Input
              value={orderData?.shippingAddress?.city}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  shippingAddress: {
                    ...orderData.shippingAddress,
                    city: e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Street</span>}
            name={["shippingAddress", "street"]}
            rules={[{ required: true, message: "Please enter street" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId).shippingAddress
                    .street
                : ""
            }
          >
            <Input
              value={orderData?.shippingAddress?.street}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  shippingAddress: {
                    ...orderData.shippingAddress,
                    street: e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item
            label={<span style={{ color: "white" }}>Postal Code</span>}
            name={["shippingAddress", "postalCode"]}
            rules={[{ required: true, message: "Please enter postal code" }]}
            initialValue={
              editOrderId
                ? orders.find((order) => order._id === editOrderId).shippingAddress
                    .postalCode
                : ""
            }
          >
            <Input
              value={orderData?.shippingAddress?.postalCode}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  shippingAddress: {
                    ...orderData.shippingAddress,
                    postalCode: e.target.value,
                  },
                })
              }
            />
          </Form.Item>
        </Form>
        <div
          style={{ marginBottom: "20px", color: "white", fontWeight: "bold" }}
        >
          Billing Address
        </div>
        <Form.Item
          label={<span style={{ color: "white" }}>Country</span>}
          name={["billingAddress", "country"]}
          rules={[{ required: true, message: "Please enter country" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).billingAddress
                  .country
              : ""
          }
        >
          <Input
            value={orderData?.billingAddress?.country}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                billingAddress: {
                  ...orderData.billingAddress,
                  country: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "white" }}>Governorate</span>}
          name={["billingAddress", "governorate"]}
          rules={[{ required: true, message: "Please enter governorate" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).billingAddress
                  .governorate
              : ""
          }
        >
          <Input
            value={orderData?.billingAddress?.governorate}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                billingAddress: {
                  ...orderData.billingAddress,
                  governorate: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "white" }}>City</span>}
          name={["billingAddress", "city"]}
          rules={[{ required: true, message: "Please enter city" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).billingAddress
                  .city
              : ""
          }
        >
          <Input
            value={orderData?.billingAddress?.city}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                billingAddress: {
                  ...orderData.billingAddress,
                  city: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "white" }}>Street</span>}
          name={["billingAddress", "street"]}
          rules={[{ required: true, message: "Please enter street" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).billingAddress
                  .street
              : ""
          }
        >
          <Input
            value={orderData?.billingAddress?.street}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                billingAddress: {
                  ...orderData.billingAddress,
                  street: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "white" }}>Postal Code</span>}
          name={["billingAddress", "postalCode"]}
          rules={[{ required: true, message: "Please enter postal code" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).billingAddress
                  .postalCode
              : ""
          }
        >
          <Input
            value={orderData?.billingAddress?.postalCode}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                billingAddress: {
                  ...orderData.billingAddress,
                  postalCode: e.target.value,
                },
              })
            }
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "white" }}>Shipping Cost</span>}
          name="shippingCost"
          rules={[{ required: true, message: "Please enter shipping cost" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).shippingCost
              : ""
          }
        >
          <InputNumber
            onChange={(value) =>
              setOrderData({ ...orderData, shippingCost: value })
            }
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "white" }}>Promo Code</span>}
          name="promoCode"
          rules={[{ required: true, message: "Please enter Promo Code" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).promoCode
              : ""
          }
        >
          <Input
            onChange={(e) =>
              setOrderData({ ...orderData, promoCode: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "white" }}>Payment Method</span>}
          name="paymentMethod"
          rules={[{ required: true, message: "Please enter Payment Method" }]}
          initialValue={
            editOrderId
              ? orders.find((order) => order._id === editOrderId).paymentMethod
              : ""
          }
        >
          <Input
            onChange={(e) =>
              setOrderData({ ...orderData, paymentMethod: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Items" name="itemsItem" style={{ width: "100%" }}>
          <Form
            name="dynamic_form_nest_item"
            style={{
              maxWidth: 600,
            }}
            autoComplete="off"
          >
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 16,
                    width: "100%",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      title={`Item ${field.name + 1}`}
                      key={field.key}
                      style={{ width: "100%" }}
                      extra={
                        <CloseOutlined onClick={() => 
                          {
                          remove(field.name);
                          setOrderData((prevState) => {
                            const items = [...prevState.items];
                            items.splice(field.name, 1);
                            return { ...prevState, items };
                          })}
                        } />
                      }
                    >
                      <Space key={field.key} style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Form.Item
                            label="Product Name"
                            name={[field.name, "productName"]}
                            // initialValue={
                            //   editOrderId
                            //     ? orders.find((order) => order._id === editOrderId)
                            //         .items[field.name].productName
                            //     : ""
                            // }
                          >
                            <Select
                              placeholder="Select a product"
                              allowClear
                              options={getProductsNames}
                              style={{ marginBottom: "10px" }}
                              showSearch
                              onChange={(value) =>
                                setOrderData((prevState) => {
                                  const items = [...prevState.items];

                                  // Check if the item at field.name is initialized, if not, initialize it
                                  if (!items[field.name]) {
                                    items[field.name] = {};
                                  }

                                  if (!items[field.name].productName) {
                                    items[field.name].productName = {};
                                  }

                                  items[field.name].productName = value;
                                  return { ...prevState, items };
                                })
                              }
                            />
                          </Form.Item>
                          <Form.Item label="Size" name={[field.name, "size"]} 
                          // initialValue={
                          //     editOrderId
                          //       ? orders.find((order) => order._id === editOrderId)
                          //           .items[field.name].size
                          //       : ""
                            
                          // }
                          >
                            <Select
                              placeholder="Select a size"
                              allowClear
                              options={[
                                { label: "XS", value: "xs" },
                                { label: "S", value: "s" },
                                { label: "M", value: "m" },
                                { label: "L", value: "l" },
                                { label: "XL", value: "xl" },
                              ]}
                              onChange={(value) =>
                                setOrderData((prevState) => {
                                  const items = [...prevState.items];

                                  // Check if the item at field.name is initialized, if not, initialize it
                                  if (!items[field.name]) {
                                    items[field.name] = {};
                                  }

                                  if (!items[field.name].size) {
                                    items[field.name].size = {};
                                  }

                                  items[field.name].size = value;
                                  return { ...prevState, items };
                                })
                              }
                              style={{
                                marginBottom: "10px",
                                width: "100%",
                              }}
                            />
                          </Form.Item>

                          <Form.Item label="Color" name={[field.name, "color"]}
                          // initialValue={
                          //     editOrderId
                          //       ? orders.find((order) => order._id === editOrderId)
                          //           .items[field.name].color
                          //       : ""
                            
                          
                          // }
                          >
                            <Select
                              placeholder="Select a color"
                              allowClear
                              options={[
                                { label: "Red", value: "red" },
                                { label: "Green", value: "green" },
                                { label: "Blue", value: "blue" },
                                { label: "Black", value: "black" },
                                { label: "White", value: "white" },
                                { label: "Yellow", value: "yellow" },
                                { label: "Orange", value: "orange" },
                                { label: "Purple", value: "purple" },
                                { label: "Pink", value: "pink" },
                                { label: "Brown", value: "brown" },
                                { label: "Grey", value: "grey" },
                                { label: "Beige", value: "beige" },
                                { label: "Gold", value: "gold" },
                                { label: "Silver", value: "silver" },
                                { label: "Bronze", value: "bronze" },
                              ]}
                              style={{ marginBottom: "10px" }}
                              showSearch
                              onChange={(value) =>
                                setOrderData((prevState) => {
                                  const items = [...prevState.items];

                                  // Check if the item at field.name is initialized, if not, initialize it
                                  if (!items[field.name]) {
                                    items[field.name] = {};
                                  }

                                  if (!items[field.name].color) {
                                    items[field.name].color = {};
                                  }

                                  items[field.name].color = value;
                                  return { ...prevState, items };
                                })
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            label="Quantity"
                            name={[field.name, "quantity"]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity is required",
                              },
                            ]}
                            // initialValue={
                            //   editOrderId
                            //     ? orders.find((order) => order._id === editOrderId)
                            //         .items[field.name].quantity
                            //     : ""
                            // }
                          >
                            <InputNumber
                              min={1}
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                setOrderData((prevState) => {
                                  const items = [...prevState.items];

                                  // Check if the item at field.name is initialized, if not, initialize it
                                  if (!items[field.name]) {
                                    items[field.name] = {};
                                  }

                                  if (!items[field.name].quantity) {
                                    items[field.name].quantity = {};
                                  }

                                  items[field.name].quantity = value;
                                  return { ...prevState, items };
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                      </Space>
                    </Card>
                  ))}
                  <Button type="dashed" onClick={() => add()} block>
                    + Add Item
                  </Button>
                </div>
              )}
            </Form.List>
          </Form>
        </Form.Item>
      </Modal>
      <Table
        dataSource={orders}
        rowKey="_id"
        columns={columns}
        pagination={{ pageSize: 5 }}
        className="order-table"
        loading={isLoading}
      />
    </div>
  );
}
