import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, Modal, Form, Input, DatePicker, Select, InputNumber } from 'antd'; // Import necessary components
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'; // Import icon components
import styles from './Vouchers.css';
import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';

export function Vouchers() { // Use voucherPage for clarity

  const [isLoading, setIsLoading] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editVoucherId, setEditVoucherId] = useState(null);
  const [form] = Form.useForm();

  const showModal = () => {
    form.resetFields();
    setIsModalVisible(true);
    setEditVoucherId(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditVoucherId(null);
  };

  const handleOk = () => {
    if (editVoucherId) {
      updateVoucher();
    } else {
      addVoucher();
    }
    setIsModalVisible(false);
  };

  const removevoucher = async (voucherId) => {
    await axios.delete(`https://api.detacheg.com/api/vouchers/${voucherId}`);
    setIsUpdated(!isUpdated);
  };

  const editvoucher = (voucherId) => {
    const voucherToEdit = vouchers.find(voucher => voucher._id === voucherId);
    if (voucherToEdit) {
      form.setFieldsValue({
        name: voucherToEdit.name,
        code: voucherToEdit.code,
        type: voucherToEdit.discountType,
        discount: voucherToEdit.discount,
      });
      setVoucherData({
        name: voucherToEdit.name,
        code: voucherToEdit.code,
        discountType: voucherToEdit.discountType,
        discount: voucherToEdit.discount,
      });
      setIsModalVisible(true);
      setEditVoucherId(voucherId);
    }
  }


  const columns = [
    {
      title: 'Voucher Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Type',
      dataIndex: 'discountType',
      key: 'discountType',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render: (text, record) => {
        return record.discountType === 'Percentage' ? `${text}%` : `${text} EGP`;
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => (
        record.status === "Inactive" ? (
          <Button style={{ backgroundColor: "red", color: "white" }} size="small" onClick={() => activateVoucher(record._id, record.status)}>
            Inactive
          </Button>
        ) : (
          <Button style={{ backgroundColor: "green", color: "white" }} size="small" onClick={() => activateVoucher(record._id, record.status)}>
            Active
          </Button>
        )
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <>
          <Popconfirm
            title="Are you sure you want to remove this voucher?"
            placement="right"
            onConfirm={() => removevoucher(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteOutlined />} size="small" style={{ backgroundColor: 'red', color: 'white' }} />
          </Popconfirm>
          <Button type="primary" icon={<EditOutlined />} size="small" onClick={() => editvoucher(record._id)} />
        </>
      ),
    },
  ];

  const updateVoucher = async () => {
    await axios.put(`https://api.detacheg.com/api/vouchers`, {
      _id: editVoucherId,
      name: voucherData.name,
      code: voucherData.code,
      discountType: voucherData.discountType,
      discount: voucherData.discount,
    });
    setIsUpdated(!isUpdated);
    form.resetFields();
    setVoucherData
      ({
        name: "",
        code: "",
        discountType: "",
        discount: 0,
        status: "Inactive"
      });
    setEditVoucherId(null);
  }

  const activateVoucher = async (voucherId, status) => {
    await axios.put(`https://api.detacheg.com/api/vouchers`, {
      _id: voucherId,
      status: status === "Active" ? "Inactive" : "Active"
    });
    setIsUpdated(!isUpdated);
  }

  const [voucherData, setVoucherData] = useState({
    name: "",
    code: "",
    discountType: "",
    discount: 0,
    status: "Inactive",
    startDate: "",
    endDate: "",
  });

  const [vouchers, setVouchers] = useState([]);

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const getVouchers = async () => {
      setIsLoading(true);
      const res = await axios.get('https://api.detacheg.com/api/vouchers');
      setVouchers(res.data);
      setIsLoading(false);
    }
    getVouchers();
  }, [isUpdated])

  const addVoucher = async () => {
    await axios.post('https://api.detacheg.com/api/vouchers', voucherData);
    setIsUpdated(!isUpdated);
    form.resetFields();
    setVoucherData({
      name: "",
      code: "",
      discountType: "",
      discount: 0,
      status: "Inactive",
      startDate: "",
      endDate: "",
    });
  }

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: "20px" }} onClick={showModal}>
        Add Voucher
      </Button>
      <Modal title="Add Voucher" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} className={styles['voucher-modal']}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleOk}
          form={form}
        >
          <Form.Item
            label={<span style={{ color: 'white' }}>Name</span>}
            name="name"
            rules={[{ required: true, message: 'Please enter voucher name' }]}
            initialValue={editVoucherId ? vouchers.find(voucher => voucher._id === editVoucherId).name : ''}
          >
            <Input onChange={(e) => setVoucherData({ ...voucherData, name: e.target.value })} />
          </Form.Item>
          <Form.Item label={<span style={{ color: 'white' }}>Code</span>} name="code" rules={[{ required: true, message: 'Please enter code' }]} initialValue={editVoucherId ? vouchers.find(voucher => voucher._id === editVoucherId).code : ''}>
            <Input onChange={(e) => setVoucherData({ ...voucherData, code: e.target.value })} />
          </Form.Item>
          <Form.Item label={<span style={{ color: 'white' }}>Type</span>} name="type" rules={[{ required: true, message: 'Please enter voucher type' }]} initialValue={editVoucherId ? vouchers.find(voucher => voucher._id === editVoucherId).type : ''}>
            <Select
              placeholder="Select a type"
              allowClear
              options={[
                { label: 'Percentage', value: 'Percentage' },
                { label: 'Fixed', value: 'Fixed' },
              ]
              }
              style={{ marginBottom: "10px" }}
              onChange={(value) => setVoucherData({ ...voucherData, discountType: value })}
            />
          </Form.Item>
          <Form.Item label={<span style={{ color: 'white' }}>Discount</span>} name="discount" rules={[{ required: true, message: 'Please enter voucher discount' }]} initialValue={editVoucherId ? vouchers.find(voucher => voucher._id === editVoucherId).discount : ''}>
            <InputNumber style={{ width: "100%" }} min={1} onChange={(value) => setVoucherData({ ...voucherData, discount: value })} />
          </Form.Item>
        </Form>
      </Modal>
      <Table dataSource={vouchers} columns={columns} pagination={{ pageSize: 5 }} className="voucher-table" loading={isLoading} />
    </>
  );
}